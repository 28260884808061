
import * as bitcoin from 'bitcoinjs-lib';
import { AddressType, CoinType, networks, NetworkType } from './paperWallet';
import { Console } from './console';
import bs58 from 'bs58';
import { bech32 } from 'bech32';

export class Wallet {

  static fromJSON(json: any): Wallet {
    if (!json.savedAddresses) {
      json.savedAddresses = [];
    }
    const wallet = new Wallet(json.address, json.type, json.network, json.coinType, json.mnemonic, json.savedAddresses);
    wallet.privateKey = json.privateKey;
    return wallet;
  }

  static toJSON(wallet: Wallet): any {
    return {
      address: wallet.address,
      privateKey: wallet.privateKey,
      type: wallet.type,
      network: wallet.network,
      coinType: wallet.coinType,
      mnemonic: wallet.mnemonic,
      savedAddresses: wallet.savedAddresses
    };
  }

  privateKey = '';
  constructor(
    public address: string,
    public type: AddressType,
    public network: NetworkType,
    public coinType: CoinType,
    public mnemonic: string,
    public savedAddresses: string[] = []
  ) {
    if (type === AddressType.BitcoinORIG) {
      this.type = AddressType.Bitcoin; // backwards compatibility
    }
    if (this.coinType === CoinType.ETH) {
      this.getEtherLib();
    }
  }

  //Encrypts the private key client side and then serverside to force the requierment of webauthn for private key access
  setPrivateKey(privateKey: string) {
    Console.log('Setting private key:', privateKey);
    this.privateKey = privateKey;
  }

  public getsmallUnitName() {

    switch (this.coinType) {
      case CoinType.BTC:
        return 'satoshi';
      case CoinType.ETH:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
      case CoinType.BNB:
      case CoinType.LINK:
        return 'wei';
      case CoinType.LTC:
        return 'litoshi';
      case CoinType.DOGE:
        return 'shibes';
      case CoinType.ADA:
        return 'lovelace';
      case CoinType.SOL:
        return 'lamport';
      // case CoinType.XRP:
      //   return 'drop';
      default:
        return 'unit';
    }
  }

  getIcon() {
    return CoinType.getSvgPath(this.coinType);
  }

  getSmallIcon(): string {
    return CoinType.getSmallSvgPath(this.coinType);
  }

  public validateAddress(addr: string = this.address): boolean {
    Console.log('Validating address:', this);
    switch (this.coinType) {
      case CoinType.ETH:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
      case CoinType.BNB:
      case CoinType.LINK:
        return this.validateEthereumAddress(addr);
      case CoinType.LTC:
      case CoinType.DOGE:
      case CoinType.BTC:
        return this.validateBitcoinAddress(addr);
      case CoinType.ADA:
        return this.validateCardanoAddress(addr);
      case CoinType.SOL:
        return this.validateSolanaAddress(addr);
      // case CoinType.XRP:
      //   return this.validateRippleAddress(addr);
      default:
        return false;
    }
  }

  validateCardanoAddress(addr: string): boolean {
    try {
      const decoded = bech32.decode(addr, 1000);
      // Cardano addresses typically use 'addr' for mainnet and 'addr_test' for testnet
      if (decoded.prefix === 'addr' || decoded.prefix === 'addr_test') {
        return true;
      }
      return false;
    } catch (error) {
      // If decoding fails, it's not a valid Cardano address
      return false;
    }
  }

  private validateBitcoinAddress(addr: string): boolean {
    const network = this.network == NetworkType.Testnet ? networks[this.coinType].testnet : networks[this.coinType].mainnet;
    try {
      bitcoin.address.toOutputScript(addr, network);
    } catch (e) {
      Console.error(e);
      return false;
    }
    return true;
  }

  private validateEthereumAddress(addr: string): boolean {
    const ethers = (window as any).ethers;
    return ethers.isAddress(addr);
  }

  private validateSolanaAddress(addr: string): boolean {
    try {
      // Decode the address using Base58
      const decoded = bs58.decode(addr);
      // Check if the decoded address is 32 bytes if it is this Might be a valid address.
      return decoded.length === 32;
    } catch (error) {
      // If decoding fails, it's not a valid address
      return false;
    }
  }

  public async getEtherLib(): Promise<any> {
    if (!(window as any).ethers) {
      (window as any).ethers = await import('ethers');
    }
    return (window as any).ethers;
  }

  public supportsTransfers(): any {
    return (this.coinType !== CoinType.ADA);
  }

}
