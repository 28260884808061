import { Injectable } from '@angular/core';
import { Wallet } from '../lib/wallet';
import { AddressType, CoinType, NetworkType, PaperWallet } from '../lib/paperWallet';
import { AuthzService } from './authz.service';
import { CallBack, EmptyAction } from '../interfaces/callback';
import * as bitcoin from 'bitcoinjs-lib';
import * as ecc from '@bitcoinerlab/secp256k1';

import { ApiService } from './api.service';
import { SharedService } from './shared.service';


bitcoin.initEccLib(ecc);

@Injectable({
  providedIn: 'root',
})
export class WalletService {

  constructor(private authzSvc: AuthzService, private apiSvc: ApiService, private sharedSvc: SharedService) {
  }

  //---------------------- Wallet Management ----------------------
  public async createWallet(coinType: CoinType, isTestnet: boolean = false): Promise<Wallet> {
    const data = await PaperWallet.create(coinType, isTestnet);
    //encrypt private key
    data.wallet.privateKey = await this.apiSvc.authnEncryptData(data.privateKey);
    return data.wallet;
  }

  public async getWalletPrivateKey(wallet: Wallet): Promise<string> {
    if (wallet.privateKey.length > 100) {
      const decrypted = await this.apiSvc.authnDecryptData(wallet.privateKey);
      return decrypted;
    } else {
      //encrypted private key
      const encrypteddata = await this.apiSvc.authnEncryptData(wallet.privateKey);
      const test = await this.apiSvc.authnDecryptData(encrypteddata);
      if (test === wallet.privateKey) {
        wallet.privateKey = encrypteddata;
        await this.sharedSvc.saveWallet(wallet);
        return test;
      } else {
        return wallet.privateKey;
      }
    }
  }

  // Define the cache at the service level.
  private balanceCache: Map<string, bigint> = new Map();
  private CACHE_DURATION_MS = 3 * 60 * 1000; // 3 minutes
  /**
 *
 * @param wallet
 * @param nonToken  if true, get the balance of the coin ie: Eth, otherwise get the balance of the token
 * @returns
 */
  public async getBalance(wallet: Wallet, nonToken = false): Promise<bigint> {
    if (nonToken && wallet.type !== AddressType.Ethereum) {
      throw new Error('Unsupported coin');
    }

    // Generate a unique key for caching, including wallet address and nonToken flag.
    const cacheKey = `${wallet.address}-${nonToken}`;

    // Check if the value is cached.
    if (this.balanceCache.has(cacheKey)) {
      return this.balanceCache.get(cacheKey) as bigint;
    }

    const type = nonToken ? CoinType.ETH : wallet.coinType;
    const callback = new CallBack('GetCryptoBalance', new EmptyAction());
    const request = { type, address: wallet.address, testnet: wallet.network === NetworkType.Testnet };
    callback.action.request = request;

    // Perform the API request if not cached.
    const result = await this.authzSvc.apiRequest(callback);
    if (result.action.result === 'FAILURE') {
      throw new Error(result.action.reason);
    }

    // Convert the result to bigint and cache it.
    const balance = BigInt(result.action.result);
    this.balanceCache.set(cacheKey, balance);

    // Set a timeout to clear this cache after a specific duration.
    setTimeout(() => {
      this.balanceCache.delete(cacheKey);
    }, this.CACHE_DURATION_MS);
    return balance;
  }
}
