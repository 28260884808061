import * as bip39 from 'bip39';
import { BIP32Factory } from 'bip32';
import * as bitcoin from 'bitcoinjs-lib';
import * as ecc from '@bitcoinerlab/secp256k1';
import { ECPairFactory, ECPairInterface } from 'ecpair';
import * as ed25519 from '@noble/ed25519';
import { sha512 } from '@noble/hashes/sha512';
import { derivePath } from 'ed25519-hd-key';
import { bech32 } from 'bech32';
import bs58 from 'bs58';
import { Wallet } from './wallet';

export enum AddressType {
  Bitcoin = 'Bitcoin',
  Ethereum = 'Ethereum',
  Cardano = 'Cardano',
  Solana = 'Solana',
  // Ripple = 'Ripple',
  Litecoin = 'Litecoin',
  Dogecoin = 'Dogecoin',
  Binance = 'Binance',
  BitcoinORIG = 'p2pkh'
}

export enum NetworkType {
  Mainnet = 'Mainnet',
  Testnet = 'Testnet'
}

export enum CoinType {
  BTC = 'Bitcoin',
  ETH = 'Ethereum',
  BNB = 'Binance Coin',
  LINK = 'Chainlink',
  ADA = 'Cardano',
  SOL = 'Solana',
  // XRP = 'Ripple',
  LTC = 'Litecoin',
  DOGE = 'Dogecoin',
  USDT = 'Tether',
  USDC = 'USD Coin',
  DAI = 'Dai'
}

export const CoinTypeArray = [
  CoinType.BTC,
  CoinType.ETH,
  CoinType.BNB,
  CoinType.LINK,
  CoinType.ADA,
  CoinType.SOL,
  // CoinType.XRP,
  CoinType.LTC,
  CoinType.DOGE,
  CoinType.USDT,
  CoinType.USDC,
  CoinType.DAI
];

export namespace CoinType {
  export function getSvgPath(coin: string): string {
    const key = Object.keys(CoinType).find(key => CoinType[key as keyof typeof CoinType] === coin);
    return `assets/images/svg/coins/${key}.svg`;
  }

  export function getSmallSvgPath(coin: CoinType): string {
    const key = Object.keys(CoinType).find(key => CoinType[key as keyof typeof CoinType] === coin);
    return `assets/images/svg/coins/Coins_Gold/${key}.svg`;
  }

  export function getAddressType(coin: string): AddressType {
    switch (coin) {
      case CoinType.BTC: //https://github.com/Blockstream/esplora/blob/master/API.md#post-tx post body to https://blockstream.info/api/tx
      case CoinType.LTC:
      case CoinType.DOGE:
        return AddressType.Bitcoin;
      case CoinType.ETH:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
      case CoinType.LINK:
        return AddressType.Ethereum; // Cloudflare Ethereum Gateway rpc free https://cryptodevhub.io/cloudflare-ethereum-gateway
      case CoinType.ADA:
        return AddressType.Cardano;
      case CoinType.SOL:
        return AddressType.Solana;
      // case CoinType.XRP:
      //   return AddressType.Ripple;
      case CoinType.BNB:
        return AddressType.Binance;
      default:
        throw new Error('Unsupported coin type');
    }
  }
}

export const networks = {
  Bitcoin: {
    mainnet: bitcoin.networks.bitcoin,
    testnet: bitcoin.networks.testnet
  },
  Litecoin: {
    mainnet: {
      messagePrefix: '\x19Litecoin Signed Message:\n',
      bech32: 'ltc',
      bip32: { public: 0x019da462, private: 0x019d9cfe },
      pubKeyHash: 0x30,
      scriptHash: 0x32,
      wif: 0xb0
    },
    testnet: {
      messagePrefix: '\x19Litecoin Signed Message:\n',
      bech32: 'tltc',
      bip32: { public: 0x0436ef7d, private: 0x0436f6e1 },
      pubKeyHash: 0x6f,
      scriptHash: 0x3a,
      wif: 0xef
    }
  },
  Dogecoin: {
    mainnet: {
      messagePrefix: '\x19Dogecoin Signed Message:\n',
      bech32: 'doge',
      bip32: { public: 0x02facafd, private: 0x02fac398 },
      pubKeyHash: 0x1e,
      scriptHash: 0x16,
      wif: 0x9e
    },
    testnet: {
      messagePrefix: '\x19Dogecoin Signed Message:\n',
      bech32: 'tdoge',
      bip32: { public: 0x043587cf, private: 0x04358394 },
      pubKeyHash: 0x71,
      scriptHash: 0xc4,
      wif: 0xf1
    }
  },
  binance: {
    mainnet: {
      messagePrefix: '\x19Binance Signed Message:\n',
      bech32: 'bnb',
      bip32: { public: 0x0488b21e, private: 0x0488ade4 },
      pubKeyHash: 0x1e,
      scriptHash: 0x16,
      wif: 0x80
    },
    testnet: {
      messagePrefix: '\x19Binance Signed Message:\n',
      bech32: 'tbnb',
      bip32: { public: 0x043587cf, private: 0x04358394 },
      pubKeyHash: 0x71,
      scriptHash: 0xc4,
      wif: 0xf1
    }

  },
  ethereum: {
    mainnet: {
      messagePrefix: '\x19Ethereum Signed Message:\n',
      bech32: 'eth',
      bip32: { public: 0x0488b21e, private: 0x0488ade4 },
      pubKeyHash: 0x1e,
      scriptHash: 0x16,
      wif: 0x80
    },
    testnet: {
      messagePrefix: '\x19Ethereum Signed Message:\n',
      bech32: 'teth',
      bip32: { public: 0x043587cf, private: 0x04358394 },
      pubKeyHash: 0x71,
      scriptHash: 0xc4,
      wif: 0xf1
    }
  }
};

const bip32 = BIP32Factory(ecc);
const ECPair = ECPairFactory(ecc);
ed25519.etc.sha512Sync = (...m) => sha512(ed25519.etc.concatBytes(...m));
export class PaperWallet {
  private mnemonic!: string; // space-separated words
  private seed!: Buffer;

  private constructor(public coinType: CoinType, public isTestnet: boolean = false) {
  }

  public static async create(coinType: CoinType, isTestnet: boolean = false): Promise<{ wallet: Wallet, privateKey: string }> {
    const paperWallet = new PaperWallet(coinType, isTestnet);
    await paperWallet.generateMnemonic();
    return paperWallet.generateWallet();
  }

  public async generateMnemonic(): Promise<void> {
    this.mnemonic = bip39.generateMnemonic(256); // 24 words
    this.seed = await bip39.mnemonicToSeed(this.mnemonic);
  }

  private async generateWallet(): Promise<{ wallet: Wallet, privateKey: string }> {
    const network = this.isTestnet ? NetworkType.Testnet : NetworkType.Mainnet;
    const type = CoinType.getAddressType(this.coinType);
    let address: string;
    let privateKey: string;

    switch (this.coinType) {
      case CoinType.BTC:
        ({ address, privateKey } = this.generateBitcoinLikeWallet('Bitcoin'));
        break;
      case CoinType.LTC:
        ({ address, privateKey } = this.generateBitcoinLikeWallet('Litecoin'));
        break;
      case CoinType.DOGE:
        ({ address, privateKey } = this.generateBitcoinLikeWallet('Dogecoin'));
        break;
      case CoinType.ETH:
      case CoinType.BNB:
      case CoinType.LINK:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
        ({ address, privateKey } = await this.generateEthereumBasedWallet());
        break;
      case CoinType.SOL:
        ({ address, privateKey } = await this.generateSolanaWallet());
        break;
      case CoinType.ADA:
        ({ address, privateKey } = await this.generateCardanoWallet());
        break;
      default:
        throw new Error('Unsupported coin type');
    }
    const wallet = new Wallet(address, type, network, this.coinType, this.mnemonic);
    await this.validateAddress(wallet, privateKey);
    return { wallet, privateKey };
  }

  public async generateCardanoWallet(): Promise<{ address: string; privateKey: string }> {
    const { mnemonicToRootKeypair, toPublic, getPubKeyBlake2b224Hash, packBaseAddress, derivePrivate } = await import('cardano-crypto.js');

    const derivationScheme = 2; // Shelley derivation scheme
    const rootKeypair = await mnemonicToRootKeypair(this.mnemonic, derivationScheme);
    const rootPrivateKey = rootKeypair.slice(0, 64); // Extract private key
    const privatekey = rootPrivateKey.toString('hex'); // Export as hex


    // Derive the spending private key (m/1852'/1815'/0'/0/0)
    const spendingPrivateKey = derivePrivate(rootKeypair, [
      1852 + 0x80000000, // Purpose
      1815 + 0x80000000, // Coin type (Cardano)
      0 + 0x80000000, // Account 0
      0, // External chain (0 for receiving addresses)
      0, // Index 0
    ]);
    const spendingPublicKey = toPublic(spendingPrivateKey);

    // Derive the staking private key (m/1852'/1815'/0'/2/0)
    const stakingPrivateKey = derivePrivate(rootKeypair, [
      1852 + 0x80000000, // Purpose
      1815 + 0x80000000, // Coin type (Cardano)
      0 + 0x80000000, // Account 0
      2, // Staking chain
      0, // Index 0
    ]);
    const stakingPublicKey = toPublic(stakingPrivateKey);

    // Generate the base address (spending + staking keys)
    const networkId = 1; // Mainnet
    const spendingKeyHash = getPubKeyBlake2b224Hash(spendingPublicKey);
    const stakingKeyHash = getPubKeyBlake2b224Hash(stakingPublicKey);
    const baseAddressBuffer = packBaseAddress(spendingKeyHash, stakingKeyHash, networkId, false);

    // Convert to Bech32 format
    const words = bech32.toWords(baseAddressBuffer);
    const baseAddress = bech32.encode('addr', words);

    return {
      address: baseAddress,
      privateKey: privatekey
    };
  }

  private async generateSolanaWallet(): Promise<{ address: string; privateKey: string }> {
    const { Keypair } = await import('@solana/web3.js');
    const seed = await bip39.mnemonicToSeed(this.mnemonic);
    const seedBuffer = Buffer.from(seed).toString('hex');
    const path44Change = `m/44'/501'/0'/0'`;
    const derivedSeed = derivePath(path44Change, seedBuffer).key;
    const keypair = Keypair.fromSeed(derivedSeed);
    const address = keypair.publicKey.toBase58();
    const privateKey = bs58.encode(keypair.secretKey);
    return {
      address,
      privateKey: privateKey,
    };
  }

  private getCoinTypeIndex(coin: string): number {
    switch (coin) {
      case 'Bitcoin':
        return this.isTestnet ? 1 : 0;
      case 'Litecoin':
        return this.isTestnet ? 1 : 2;
      case 'Dogecoin':
        return this.isTestnet ? 1 : 3;
      default:
        throw new Error('Unsupported coin type');
    }
  }

  private generateBitcoinLikeWallet(coin: 'Bitcoin' | 'Litecoin' | 'Dogecoin'): {
    address: string;
    privateKey: string;
  } {
    const networkConfig = networks[coin];
    const network = this.isTestnet ? networkConfig.testnet : networkConfig.mainnet;
    const coinTypeIndex = this.getCoinTypeIndex(coin);

    // Dogecoin typically uses BIP44 (44') instead of BIP84 (84'), especially for legacy addresses.
    const path = coin === 'Dogecoin'
      ? `m/44'/${coinTypeIndex}'/0'/0/0`
      : `m/84'/${coinTypeIndex}'/0'/0/0`;

    const keyPair = this.deriveBitcoinKeyPair(path, network);

    // Use p2pkh for Dogecoin to generate a legacy address.
    const payment = coin === 'Dogecoin'
      ? bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network })
      : bitcoin.payments.p2wpkh({ pubkey: keyPair.publicKey, network });

    return { address: payment.address!, privateKey: keyPair.toWIF() };
  }

  private deriveBitcoinKeyPair(path: string, network: bitcoin.networks.Network): ECPairInterface {
    const root = bip32.fromSeed(this.seed, network);
    const child = root.derivePath(path);

    if (!child.privateKey) {
      throw new Error('No private key found');
    }

    return ECPair.fromPrivateKey(child.privateKey, { network });
  }

  private async generateEthereumBasedWallet(): Promise<{ address: string; privateKey: string; }> {
    const ethers = await this.getEtherLib();
    const wallet = ethers.Wallet.fromPhrase(this.mnemonic);
    return { address: wallet.address, privateKey: wallet.privateKey };
  }

  private async validateAddress(wallet: Wallet, privateKey): Promise<void> {

    switch (this.coinType) {
      case CoinType.ETH:
      case CoinType.BNB:
      case CoinType.LINK:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
        return this.validateEthereumWallet(wallet, privateKey);
      case CoinType.DOGE:
        return this.validateDogecoinWallet(wallet, privateKey);
      case CoinType.LTC:
      case CoinType.BTC:
        return this.validateBitcoinWallet(wallet, privateKey);
      case CoinType.SOL:
        return this.validateSolWallet(wallet, privateKey);
      case CoinType.ADA:
        return this.validateCardanoEnterpriseWallet(wallet, privateKey);
      default:
        throw new Error('Unsupported coin type');
    }
  }

  private async validateCardanoEnterpriseWallet(wallet: Wallet, privateKey: string): Promise<void> {
    try {
      const { isValidShelleyAddress, addressToBuffer, getPubKeyBlake2b224Hash, toPublic, packEnterpriseAddress } = await import('cardano-crypto.js');

      if (!isValidShelleyAddress(wallet.address)) {
        throw new Error('Invalid Cardano Shelley address.');
      }

      // Decode the address to extract information
      const addressBuffer = addressToBuffer(wallet.address);
      const header = addressBuffer[0];
      const type = (header >> 4) & 0x0f; // Extract the first 4 bits for address type

      if (type !== 6) {
        throw new Error('Address is not an enterprise address.');
      }

      const spendingHash = addressBuffer.slice(1, 29); // Extract spending key hash

      // Verify that the private key matches the address
      const privateKeyBuffer = Buffer.from(privateKey, 'hex');
      const publicKey = toPublic(privateKeyBuffer);
      const derivedSpendingHash = getPubKeyBlake2b224Hash(publicKey);

      if (!spendingHash.equals(derivedSpendingHash)) {
        throw new Error('The private key does not match the wallet address.');
      }

      // Optionally, reconstruct the enterprise address and compare
      const networkId = header & 0x0f; // Extract network ID from the last 4 bits of the header
      const reconstructedAddressBuffer = packEnterpriseAddress(derivedSpendingHash, networkId, false);
      const reconstructedAddress = bech32.encode('addr', bech32.toWords(reconstructedAddressBuffer));

      if (reconstructedAddress !== wallet.address) {
        throw new Error('Reconstructed address does not match the provided address.');
      }

      console.log('Wallet validation successful.');
    } catch (error: any) {
      console.error('Wallet validation failed:', error.message ? error.message : error);
      throw new Error(`Validation failed: ${error.message ? error.message : error}`);
    }
  }

  private async validateSolWallet(wallet: Wallet, privateKey): Promise<void> {
    const { Keypair } = await import('@solana/web3.js');
    const bsprivateKey = bs58.decode(privateKey);
    const keypair = Keypair.fromSecretKey(bsprivateKey);

    if (wallet.address !== keypair.publicKey.toBase58()) {
      throw new Error('Invalid address: public key mismatch');
    }
  }

  private async validateEthereumWallet(wallet: Wallet, privateKey): Promise<void> {
    const ethers = (window as any).ethers;
    const ewallet = new ethers.Wallet(privateKey);
    if (wallet.address != ewallet.address) {
      throw new Error('Invalid address');
    }
  }

  private async validateBitcoinWallet(wallet: Wallet, privateKey) {
    const networkConfig = networks[wallet.coinType];
    const network = wallet.network === NetworkType.Testnet ? networkConfig.testnet : networkConfig.mainnet;

    const keyPair = ECPair.fromWIF(await privateKey, network);
    const payment = bitcoin.payments.p2wpkh({ pubkey: keyPair.publicKey, network });
    const derivedAddress = payment.address;

    if (wallet.address !== derivedAddress) {
      throw new Error('Invalid address');
    }
  }

  private async validateDogecoinWallet(wallet: Wallet, privateKey) {
    const networkConfig = networks[wallet.coinType];
    const network = wallet.network === NetworkType.Testnet ? networkConfig.testnet : networkConfig.mainnet;

    // Derive key pair from the wallet's WIF private key
    const keyPair = ECPair.fromWIF(await privateKey, network);

    // Generate a P2PKH address for Dogecoin
    const payment = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network });
    const derivedAddress = payment.address;

    // Check if the derived address matches the provided address
    if (wallet.address !== derivedAddress) {
      throw new Error('Invalid address');
    }
  }

  public getMnemonic(): string {
    return this.mnemonic;
  }

  private async getEtherLib(): Promise<any> {
    if (!(window as any).ethers) {
      (window as any).ethers = await import('ethers');
    }
    return (window as any).ethers;
  }
}
