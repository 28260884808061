import { RecordI } from '../interfaces/records';
import { Injectable } from '@angular/core';
import { db } from '../db/json';
import { Console } from '../lib/console';

@Injectable({
  providedIn: 'root'
})
export class RecordsService {
  getRecordByArchiveID(archiveID: string) {
    return this.db.records.getRecordByArchiveID(archiveID);
  }

  removeArchive(id: string) {
    this.db.records.removeArchive(id);
  }

  refresh() {
    this.db.refresh();
  }

  constructor(private db: db) { }
  recordsList$ = this.db.records.getRecordObservable();

  // Returns the ID of the new record
  async add(recordObj: RecordI): Promise<number> {
    return this.db.records.addRecord(recordObj)
  }

  async update(object: RecordI, id: number) {
    if (id !== -1) {
      await this.db.records.updateRecord(id, object);
    } else {
      throw new Error('Id not set');
    }
  }

  async updateKey(object: RecordI, id: number) {
    Console.log('UpdateKey ', object, id);
    if (id !== -1) {
      await this.db.records.updateRecordKey(id, object)
    } else {
      throw new Error('Id not set');
    }
  }

  async get(id: number) {
    if (id !== -1) {
      return this.db.records.getRecord(id);
    } else return {} as RecordI

  }

  async clone(id: number) {
    if (id !== -1) {
      let object = await this.db.records.getRecord(id);
      if (!object) throw new Error('Does not exist');
      delete object.id;
      await this.add(object);
    } else {
      throw new Error('Id not set');
    }
  }

  async delete(id: number) {
    if (id !== -1) {
      let object = await this.db.records.getRecord(id);
      if (!object) throw new Error('Does not exist');
      await this.db.records.deleteRecord(id)
    }
  }
}
