import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { State } from 'src/app/components/modal/modals/state';
import { environment } from 'src/environments/environment';
import { SwPush } from '@angular/service-worker';
import { ErrorService } from 'src/app/services/error.service';
import { Console } from 'src/app/lib/console';

@Component({
  selector: 'notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
  standalone: true,
  imports: [FormsModule, TranslateModule]
})
export class NotificationModalComponent implements OnDestroy {

  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>;
  @ViewChild("buttonContainer") buttonContainer!: ElementRef<HTMLInputElement>;

  @Output() response = new EventEmitter<State>();
  @Input() data!: { title: string, message: String };

  constructor(private swPush: SwPush, private errorSvc: ErrorService, private translate: TranslateService) {
    //Small screen soft keyboards are a pain
    window.visualViewport?.addEventListener("resize", this.resizeListent);
  }

  //Small screen soft keyboards are a pain
  private resizeListent = () => {
    Console.log('resize');
    this.buttonContainer.nativeElement.scrollIntoView(false); // aligned to the bottom of the visible area for soft keyboards
  };

  ngOnDestroy(): void {
    //Small screen soft keyboards are a pain
    window.visualViewport?.removeEventListener("resize", this.resizeListent);
  }

  async send() {
    try {
      Console.log('Requesting permission');
      const permissions = await Notification.requestPermission();
      Console.log('Permission', permissions);
      const isBrave = ((<any>navigator).brave && await (<any>navigator).brave.isBrave() || false);
      let subscription: PushSubscription | null = null;
      if (permissions == 'granted') {
        try {
          try {
            await this.swPush.unsubscribe();
          } catch (ignore) {
            Console.log('Not subscribed');
          }
          subscription = await this.swPush.requestSubscription({
            serverPublicKey: environment.envVar.VAPID_PUBLIC
          });
        } catch (err) {
          Console.error('Brave', isBrave);
          if (isBrave) {
            this.errorSvc.process(this.translate.instant('MODALS.NOTIFICATION.ERROR1'));
          } else {
            this.errorSvc.process(err);
          }
        }
      } else {
        this.errorSvc.process(this.translate.instant('MODALS.NOTIFICATION.ERROR2'));
      }
      if (subscription) {
        const lang = this.translate.currentLang;
        const subscriptionJSONObject: any = subscription.toJSON();
        subscriptionJSONObject.lang = lang;
        Console.log('Push subscription', subscriptionJSONObject);
        this.response.emit({ value: JSON.stringify(subscriptionJSONObject) });
      } else {
        this.response.emit({ value: null });
      }
    } catch (err) {
      this.errorSvc.process(err);
      this.response.emit({ value: null });
    }
  }

  async cancel() {
    this.response.emit({ value: null });
  }
}
