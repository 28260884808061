import { RecordI, CheckboxI } from '../../../interfaces/records';
import { Component, ViewChild, ElementRef, ChangeDetectorRef, Input, OnDestroy, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { bgImages, bgColors, bgColorsConst, bgImagesConst } from 'src/app/interfaces/tooltip';
import { SharedService } from 'src/app/services/shared.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthzService } from 'src/app/services/authz.service';
import { CboxDonePipe } from '../../../pipes/cbox-done.pipe';
import { ph } from '../../../pipes/ph.pipe';
import { NgIf, NgTemplateOutlet, NgFor, KeyValuePipe, NgClass } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { LabelI } from 'src/app/interfaces/labels';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent } from '../../modal/modal.component';
import { RecordArchive } from 'src/app/interfaces/archive';
import { ApiService } from 'src/app/services/api.service';
import { Console } from 'src/app/lib/console';
import { RecordsService } from 'src/app/services/records.service';
import { FileAttachmentLib } from '../fileAttachmentLib';
import { QuillModule } from 'ngx-quill';
import { FormsModule } from '@angular/forms';
import { Wallet } from 'src/app/lib/wallet';
import { CoinTypeArray } from 'src/app/lib/paperWallet';

const printableRegex = /\p{L}|\p{N}|\p{P}|\p{S}/u;
const letters = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"²^\\|,.<>\/?éèçµ]$/i

type InputLengthI = { title?: number, body?: number, cb?: number }
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [FormsModule, QuillModule, CdkDropList, NgIf, NgClass, NgTemplateOutlet, NgFor, KeyValuePipe, ph, CboxDonePipe, TranslateModule],
  encapsulation: ViewEncapsulation.None // This is important to apply global styles to the editor
})
export class InputComponent implements OnInit, OnDestroy, AfterViewInit {
  public editorContent = '';
  public editorModules: any;
  public currentLabel: string | undefined;
  public walletSupport = true;
  private routeSub!: Subscription;
  public pageDirty = false;
  numnerOfCoinTypes = CoinTypeArray.length;

  labelsChanged = false;
  titleplacehoder = this.translate.instant('RECORDS.INPUT.TITLE');
  showTitlePH = true;
  @ViewChild("main") main!: ElementRef<HTMLDivElement>
  //? Placeholder  ----------------------------------------------------
  @ViewChild("recordPlaceholder") recordPlaceholder!: ElementRef<HTMLDivElement>
  //? record  -----------------------------------------------------
  @ViewChild("recordMain") recordMain!: ElementRef<HTMLDivElement>
  @ViewChild("recordContainer") recordContainer!: ElementRef<HTMLDivElement>
  @ViewChild("iconsContainer") iconsContainer!: ElementRef<HTMLDivElement>
  @ViewChild("recordTitle") recordTitle!: ElementRef<HTMLDivElement>
  @ViewChild("recordBody") recordBody?: ElementRef<HTMLDivElement>
  @ViewChild("recordPin") recordPin!: ElementRef<HTMLDivElement>
  //? checkbox  -----------------------------------------------------
  @ViewChild("cboxInput") cboxInput!: ElementRef<HTMLDivElement>
  @ViewChild("cboxPh") cboxPh?: ElementRef<HTMLDivElement>
  @ViewChild("moreMenuTtBtn") moreMenuTtBtn?: ElementRef<HTMLDivElement> // needed in the html
  @ViewChild("labelInput") labelInput !: ElementRef<HTMLInputElement>
  @ViewChild("labelError") labelError !: ElementRef<HTMLInputElement>
  private mutationObserver!: MutationObserver;

  //? -----------------------------------------------------
  @Input() isEditing = false
  @Input() recordToEdit: RecordI = {
    recordTitle: "",
    pinned: false,
    bgColor: "",
    bgImage: "",
    color: "",
    isCbox: false,
    labels: [],
    archive: null,
    wallet: undefined
  }

  @Input() modalController!: ModalComponent
  //? -----------------------------------------------------
  checkBoxes: CheckboxI[] = []
  labels: LabelI[] = []

  isCboxCompletedListCollapsed = true
  isCbox = new BehaviorSubject<boolean>(false)
  inputLength = new BehaviorSubject<InputLengthI>({ title: 0, body: 0, cb: 0 })
  //
  bgColorsConst = bgColorsConst
  bgImagesConst = bgImagesConst
  moreMenuEls = {
    delete: {
      disabled: true,
    },
    copy: {
      disabled: true,
    },
  }
  numberOfWallets = 0;

  constructor(private cd: ChangeDetectorRef, public Shared: SharedService, private router: Router, private route: ActivatedRoute, public authSvc: AuthzService, private translate: TranslateService, private apiSvc: ApiService, private recordsSvc: RecordsService) {
    if (this.authSvc.appConfig.tier === 0 || this.authSvc.appConfig.tier === 4) this.walletSupport = false;
  }

  ngOnInit(): void {
    this.configureToolbar();
    this.routeSub = this.route.params.subscribe((event: any) => {
      this.currentLabel = event['name'];
      if (this.currentLabel === 'Wallet') {
        this.numberOfWallets = this.Shared.getWallets().length;
      }
    });
    screen.orientation.addEventListener('change', this.setMaxBodySize);

  }

  private configureToolbar(): void {
    const isMobile = window.innerWidth < 600;
    this.editorModules = {
      toolbar: {
        container: isMobile ? [
          ['bold', 'italic', 'underline'],        // Simplified toolbar for mobile
          [{ 'list': 'bullet' }, { 'list': 'ordered' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        ] : [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['formula'],
          ['clean']
        ]
      }
    };
  }

  // ? labels ----------------------------------------------------

  async addLabel(el: HTMLInputElement) {
    if (!el) return
    try {
      const labelName = el.value;
      await this.addLabelByName(labelName);
      this.labelError.nativeElement.hidden = true;
      el.value = '';
    } catch (x: any) {
      Console.log(x);
      if (x.message === "Name Exists") {
        this.labelError.nativeElement.hidden = false; el.focus();
      }
    }
  }

  private async addLabelByName(labelName: string) {
    // do not readd if alread in  list
    const index = this.labels.findIndex(x => x.name === labelName);
    if (index !== -1) return;

    const labels = this.Shared.label.list;
    let cl = labels.find((x: { name: string; }) => x.name === labelName);
    if (!cl) {
      const id = await this.Shared.label.db.add({ name: labelName });
      cl = { id: id, name: labelName, added: true };
    }
    if (cl) {
      cl.added = true;
      this.labels.push(cl);
      if (this.isEditing) {
        this.saveRecord(false);
      }
    } else {
      Console.error('Label not found');
    }
  }

  labelClicked(label: LabelI) {
    if (label.name === 'Wallet') {
      this.openWalletModal();
      this.closeRecord();
    }
  }

  openFile(archive: RecordArchive) {
    Console.log(archive);
    this.router.navigate(['/archives', archive.archiveID]);
  }

  async removeFile() {
    this.recordToEdit.archive = null;
    await this.saveRecord(false);
  }

  async attachFile() {
    const lib = new FileAttachmentLib(this.apiSvc, this.modalController, this.translate, this.recordsSvc, this.recordToEdit);
    const archive = await lib.attachFile();
    if (archive) {
      this.recordToEdit.archive = { archiveID: archive.archiveID, name: archive.name };
      if (this.isEditing) {
        this.saveRecord(false);
      }
    }
  }

  private async openWalletModal() {
    let current = this.recordToEdit.wallet;
    Console.log('Open Wallet Modal', current);
    let wallet
    try {
      wallet = await this.modalController.displayWallet(current ? current : null);
    } catch (x: any) {
      Console.error(x);
    }
    if (wallet) {
      if (current && wallet.address == current.address) {
        // no change
        return;
      } else {
        if (!current) { // new wallet
          Console.log('New Wallet')
          if (!this.recordToEdit.labels.find(x => x.name === 'Wallet')) {
            await this.addLabelByName('Wallet');
          }
        }
        this.recordToEdit.wallet = wallet;
        this.Shared.record.db.updateKey({ wallet: wallet });
      }
    }
  }

  private async toggleReadOnly() {
    this.recordToEdit.ro = !this.recordToEdit.ro;
    this.Shared.record.db.updateKey({ ro: this.recordToEdit.ro });
  }

  /*
  * Support reorgering of checkboxes
  */
  dropCBox(event: CdkDragDrop<string[]>) {
    this.setDirty();
    moveItemInArray(this.checkBoxes, event.previousIndex, event.currentIndex);
  }

  //? placeholder  --------------------------------------------------
  toggleRecordVisibility(condition: boolean) {
    if (condition) {
      this.recordPlaceholder.nativeElement.hidden = true; this.recordMain.nativeElement.hidden = false
    } else {
      this.recordPlaceholder.nativeElement.hidden = false; this.recordMain.nativeElement.hidden = true
    }
  }

  getCoinIcon(wallet: Wallet | undefined): string {
    if (!wallet) return '';
    if (typeof wallet == 'object') {
      wallet = Wallet.fromJSON(wallet);
    }
    return wallet.getIcon();
  }

  async recordPhClick() {

    this.labelsChanged = false;
    this.recordTitle.nativeElement.innerHTML = this.translate.instant('RECORDS.INPUT.TITLE');
    this.showTitlePH = true;
    this.toggleRecordVisibility(true)
    if (this.isCbox.value) this.cboxPh?.nativeElement.focus()
    else this.recordBody?.nativeElement.focus()
    if (!this.isEditing) {
      this.inputLength.next({ title: 0, body: 0, cb: 0 })
      document.addEventListener('mousedown', this.mouseDownEvent)
    }
    this.labels = JSON.parse(JSON.stringify(this.Shared.label.list));

    if (this.currentLabel == 'Wallet' && this.walletSupport && !this.isEditing) {
      this.saveRecord(true);
    }
  }

  mouseDownEvent = async (event: Event) => {
    if (this.isEditing) return;
    let el = this.main.nativeElement;
    let isTooltipOpen: any = document.querySelector('[data-is-tooltip-open="true"]')
    // if tooltip is open, we close it
    if (isTooltipOpen !== null) {
      if (!(el as any).contains(event.target) && !isTooltipOpen.contains(event.target)) { }
    }
    // else we close the record
    else if (!(el as any).contains(event.target)) {
      Console.log('disabled close save')
    }
  }

  closeRecord() {
    this.toggleRecordVisibility(false);
    document.removeEventListener('mousedown', this.mouseDownEvent);
    this.reset();
    this.Shared.closeModal.next(true);
  }

  //? record  -----------------------------------------------------

  async saveRecord(close = false) {
    this.cboxInput?.nativeElement.blur();
    let title = this.recordTitle.nativeElement.innerHTML;
    if (this.showTitlePH) title = '';
    // Add current label to the record
    if (!this.labelsChanged) {
      const cl = this.labels.find(x => x.name === this.currentLabel);
      if (cl && !cl.added) {
        cl.added = true;
        if (cl.name === 'Wallet') {
          const wallet = await this.modalController.displayWallet(null);
          Console.log('input save: wallet', wallet);
          if (wallet) {
            this.recordToEdit.wallet = wallet;
            title = wallet.coinType
          }
          this.numberOfWallets++;
          console.log('number of wallets', this.numberOfWallets);
        }
      }
    }
    let recordObj: RecordI = {
      recordTitle: title,
      recordBody: this.editorContent ? this.editorContent : '',
      pinned: this.recordPin.nativeElement.dataset['pinned'] === "true",
      bgColor: this.recordMain.nativeElement.style.backgroundColor,
      bgImage: this.recordContainer.nativeElement.style.backgroundImage,
      color: this.recordMain.nativeElement.style.color,
      checkBoxes: this.checkBoxes,
      isCbox: this.isCbox.value,
      labels: this.labels.filter(x => x.added),
      archive: this.recordToEdit.archive,
      wallet: this.recordToEdit.wallet,
    }
    if (!recordObj.recordTitle && !recordObj.recordBody) {
      if (recordObj.archive) {
        recordObj.recordTitle = recordObj.archive.name;
      } else {
        if (recordObj.checkBoxes) {
          const x = recordObj.checkBoxes[0];
          if (x && x.data) {
            recordObj.recordTitle = recordObj.checkBoxes[0].data;
          }

        } else { recordObj.recordTitle = this.translate.instant('RECORDS.INPUT.UNTITLED'); }
      }
    }
    if (recordObj.recordTitle.length || recordObj.recordBody && recordObj.recordBody?.length || this.checkBoxes.length) {
      if (this.isEditing) {
        await this.Shared.record.db.update(recordObj);
        if (close) {
          this.Shared.closeModal.next(true);
        }
      } else {
        await this.Shared.record.db.add(recordObj);
      }
    }
    this.pageDirty = false;
    if (close) {
      this.closeRecord();

      //if lockoutguard is not active, prompt to configure it
      if (this.authSvc.appConfig.isAdmin && !this.authSvc.appConfig.guardSet && !this.authSvc.appConfig.hg) {
        const response = await this.modalController.displayQuestion(this.translate.instant('GUARD.PROMPT.TITLE'), this.translate.instant('GUARD.PROMPT.MSG'), this.translate.instant('NO'), null, this.translate.instant('YES'));
        if (response === 'TWO') {
          this.router.navigate(['/config', { action: "GUARD" }]);
        }
      }
    }
  }

  reset() {
    Console.log('reset', this.recordToEdit);
    if (this.recordToEdit) {
      this.recordToEdit = {
        recordTitle: "",
        pinned: false,
        bgColor: "",
        bgImage: "",
        color: "",
        isCbox: false,
        labels: [],
        archive: null,
        wallet: undefined
      }
    }
    this.labelsChanged = false;
    this.showTitlePH = true;
    this.recordTitle.nativeElement.innerHTML = '';
    this.editorContent = '';
    if (!this.authSvc.appConfig.ro) this.recordPin.nativeElement.dataset['pinned'] = 'false'
    this.recordContainer.nativeElement.style.backgroundImage = '';
    this.recordMain.nativeElement.style.backgroundColor = '';
    this.recordMain.nativeElement.style.borderColor = '';
    this.iconsContainer.nativeElement.style.backgroundColor = '';
    this.labels = [];


    this.checkBoxes = [];
    this.isCbox.next(false);

    this.isCboxCompletedListCollapsed = false;
    this.inputLength.next({ title: 0, body: 0, cb: 0 });
    this.pageDirty = false;
  }

  pasteEvent(event: ClipboardEvent) {
    this.setDirty();
    event.preventDefault();
    let text = event.clipboardData?.getData('text/plain');
    let target = event.target as HTMLDivElement;
    target.innerText += text;
    let sel = window.getSelection();
    sel?.selectAllChildren(target);
    sel?.collapseToEnd();
  }


  /**
   * Calculates the Modal maximum body size area so it scrolls correctly between the header and footer
   *
   */
  setMaxBodySize = () => {
    const iscbox = this.isCbox.value || this.recordToEdit.isCbox;
    Console.log('setMaxBodySize iscbox', iscbox);
    const targetElement: HTMLElement | null = iscbox ? this.recordContainer.nativeElement.querySelector('.cbox-list') : this.recordContainer.nativeElement.querySelector('.ql-editor');
    if (!targetElement) {
      Console.log('targetElement not found');
      return
    }

    targetElement.style.minHeight = '50vh'; //set here because we want it only to be applied when the element is being edited or created, not in the list of records

    setTimeout(() => {
      if (!this.recordContainer) return;
      const headerDiv: HTMLElement = this.recordContainer.nativeElement.querySelector('.title-container')!;
      if (!headerDiv) return;

      const footerDiv: HTMLElement = this.recordContainer.nativeElement.querySelector('.record-footer')!;

      const modal: HTMLElement = footerDiv.closest('.modal-container')!;
      let maxheight = modal.offsetHeight;
      Console.log('maxheight', maxheight);
      if (!maxheight) return;

      const screenheight = screen.availHeight;
      Console.log('screenheight', screenheight);

      maxheight = this.getUsableScreenDimensions();
      maxheight *= 0.98;
      maxheight = Math.floor(maxheight);
      Console.log('adjusted maxheight', maxheight);

      let headerHeight = 0;
      Array.from(headerDiv.children).forEach(child => {
        const height = (child as HTMLElement).offsetHeight;
        headerHeight += height
      });
      Console.log('headerHeight', headerHeight);
      if (!headerHeight) return;

      let footerHeight = 0;
      Array.from(footerDiv.children).forEach(child => {
        const height = (child as HTMLElement).clientHeight;
        footerHeight += height
      });
      Console.log('footerHeight', footerHeight);
      let setmaxheight = maxheight - footerHeight - headerHeight;
      Console.log('setmaxheight', setmaxheight);
      Console.log('footerDiv', footerDiv);

      if (!iscbox) {
        const toolbar: HTMLElement = this.recordContainer.nativeElement.querySelector('.ql-toolbar')!;
        Console.log('toolbar', toolbar);
        if (toolbar) {
          const toolbarHeight = toolbar.clientHeight;
          Console.log('toolbarHeight', toolbarHeight);
          setmaxheight -= toolbarHeight;
        } else {
          Console.log('toolbar not found');
        }
      }
      targetElement.style.setProperty('--dynamic-height', `${setmaxheight}px`);

    }, 250);
  }

  private getUsableScreenDimensions() {
    // Get the viewport width and height
    const viewportHeight = window.innerHeight;

    // Get the height of the visible part of the document
    const documentHeight = document.documentElement.clientHeight;

    // The usable height excludes any visible UI like the address bar on mobile
    const usableHeight = Math.min(viewportHeight, documentHeight);
    Console.log('usableHeight', usableHeight);
    return usableHeight

  }

  editorCreated(editor: any) {
    Console.log('editorCreated', editor);
    this.setMaxBodySize();
  }

  setDirty() {
    this.pageDirty = true;
  }

  //? checkboxes  --------------------------------------------------

  cboxPhKeyDown(event: KeyboardEvent) {
    event.preventDefault()
    const key = event.key;
    const isLetter = letters.test(key);
    // ex : if he clicked the f1 btn for example, nothing would happen, otherwise :
    if (!isLetter && key != 'Unidentified') return // android does not send key codes....
    let enteredValue = key == 'Unidentified' ? '' : key;
    this.addCheckBox(enteredValue) // a new checkbox will appear in the html
    this.cd.detectChanges();
    let el = document.querySelector(`[data-cbox-last="true"]`);
    // we move the cursor to the end, so the user will just continue what he typed before
    let sel = window.getSelection();
    if (el) sel?.selectAllChildren(el);
    sel?.collapseToEnd();
  }

  cboxPhPasteEvent(event: ClipboardEvent) {
    event.preventDefault();
    let text = event.clipboardData?.getData('text/plain');
    if (!text) return;
    let target = event.target as HTMLDivElement;
    let lines = text.split('\n');
    lines.forEach(line => {
      if (line.length > 0 && printableRegex.test(line)) {
        Console.log(`line x${line}x`);
        this.addCheckBox(line);
      }
    });
    let sel = window.getSelection();
    sel?.selectAllChildren(target);
    sel?.collapseToEnd();
  }

  addCheckBox(data: string) {
    this.setDirty();
    this.checkBoxes.push({
      done: false,
      data: data,
      id: this.findFreeId()
    })
    this.inputLength.next({ ...this.inputLength.value, cb: this.checkBoxes.length });
  }

  // this function will return the first free id of the checkboxes
  private findFreeId() {
    let ids = this.checkBoxes.map(x => x.id);
    let i = 0;
    while (ids.includes(i)) i++;
    return i;
  }

  cBoxKeyDown($event: KeyboardEvent, id: number) {
    this.setDirty();
    let target = $event.target as HTMLDivElement;
    if ($event.key === 'Enter') {
      $event.preventDefault();
      this.cboxPh!.nativeElement.focus();
    }
    if ($event.key === 'Backspace' && target.innerText.length === 0) {
      this.cboxPh!.nativeElement.focus();
      this.cboxTools(id).remove();
    }
  }

  cboxTools(id: number) {
    let i = this.checkBoxes.findIndex(x => x.id === id);
    let actions = {
      remove: () => {
        this.setDirty();
        this.checkBoxes.splice(i, 1);
        this.inputLength.next({ ...this.inputLength.value, cb: this.checkBoxes.length })
      },
      check: () => {
        this.setDirty();
        this.checkBoxes[i].done = !this.checkBoxes[i].done
      },
      update: (el: HTMLDivElement) => {
        this.setDirty();
        let elValue = el?.innerHTML
        this.checkBoxes[i].data = elValue
      }
    }
    return actions
  }

  //? isEditing  -----------------------------------------------------------

  innerData(record: RecordI) {
    this.recordPhClick();
    if (record.recordTitle.length == 0) {
      this.recordTitle.nativeElement.innerHTML = this.titleplacehoder;
      this.showTitlePH = true;
    } else {
      this.recordTitle.nativeElement.innerHTML = record.recordTitle;
      this.showTitlePH = false;
    }
    this.editorContent = record.recordBody!;
    if (!this.authSvc.appConfig.ro) this.recordPin.nativeElement.dataset['pinned'] = String(record.pinned);
    this.recordContainer.nativeElement.style.backgroundImage = record.bgImage;
    this.recordMain.nativeElement.style.backgroundColor = record.bgColor;
    this.recordMain.nativeElement.style.borderColor = record.bgColor;
    this.recordMain.nativeElement.style.color = record.color;
    this.iconsContainer.nativeElement.style.backgroundColor = record.bgColor;
    if (record.checkBoxes) this.checkBoxes = record.checkBoxes;
    this.isCbox.next(record.isCbox);

    this.inputLength.next({ title: record.recordTitle.length, body: record.recordBody ? record.recordBody?.length : 0, cb: record.checkBoxes?.length! });
    record.labels.forEach(noteLabel => {
      let label = this.labels.find(x => x.name === noteLabel.name)
      if (label) label.added = noteLabel.added
    })
    this.cd.detectChanges();
  }

  //? tooltip  -----------------------------------------------------------

  openTooltip(button: HTMLDivElement, tooltipEl: HTMLDivElement) {
    this.Shared.createTooltip(button, tooltipEl);
  }

  moreMenu(tooltipEl: HTMLDivElement) {
    let actions = {
      // ToDo rename to delete
      trash: async () => {
        if (this.isEditing) {
          let result = await this.modalController.displayQuestion(this.translate.instant('RECORDS.DELETE'), this.translate.instant('RECORDS.DELETE_CONFIRMATION'), this.translate.instant('CANCEL'), null, this.translate.instant('RECORDS.DELETE'));
          if (result === 'TWO') {
            if (this.recordToEdit.wallet) {
              result = await this.modalController.displayInput(this.translate.instant('RECORDS.DELETE'), this.translate.instant('RECORDS.DELETE_WALLET'), false);
              if (result != this.translate.instant('RECORDS.NO_REGRET')) {
                console.log('delete wallet', result);
                return;
              }
              this.numberOfWallets--;
            }
            if (this.recordToEdit.archive) {
              result = await this.modalController.displayQuestion(this.translate.instant('RECORDS.DELETE'), this.translate.instant('RECORDS.DELETE_ARCHIVE'), this.translate.instant('CANCEL'), this.translate.instant('DELETE'), this.translate.instant('NO'));
              if (result == 'ONE') {
                const archive = this.recordToEdit.archive;
                await this.apiSvc.deleteArchive(archive.archiveID);
              } else if (result == 'ZERO') {
                return;
              }
            }
            await this.Shared.record.db.delete();
          }
        }
        this.Shared.closeModal.next(true);
      },
      wallet: async () => {
        this.openWalletModal();
      },
      toggleReadOnly: async () => {
        this.toggleReadOnly();
      },
      toggleCbox: () => {
        this.isCbox.next(!this.isCbox.value);
      }
    }
    this.Shared.closeTooltip(tooltipEl);
    return actions;
  }

  colorMenu = {
    bgColor: (data: bgColors) => {
      this.setDirty();
      this.recordMain.nativeElement.style.backgroundColor = data.bg;
      this.recordMain.nativeElement.style.borderColor = data.bg;
      this.iconsContainer.nativeElement.style.backgroundColor = data.bg;
      this.recordMain.nativeElement.style.color = data.color;
    },
    bgImage: (data: bgImages) => {
      this.setDirty();
      this.recordContainer.nativeElement.style.backgroundImage = `url(${data.img})`;
      this.recordMain.nativeElement.style.color = data.color;
    }
  }

  //?  -----------------------------------------------------------
  // because we don't have the spread operator in the template, we need to do this :
  updateInputLength(type: InputLengthI) {
    this.setDirty();
    if (type.title != undefined) {
      this.inputLength.next({ ...this.inputLength.value, title: type.title });
      this.showTitlePH = false;
    }
    if (type.body != undefined) this.inputLength.next({ ...this.inputLength.value, body: type.body });
  }

  textChanged(event) {
    if (event.source === 'user') {
      this.setDirty();
    }
  }

  //? -----------------------------------------------------------

  saveRecordSubscription?: Subscription;
  ngAfterViewInit() {
    this.inputLength.subscribe(x => {
      if ((x.title) || (x.body) || (x.cb) || this.authSvc.appConfig.ro) {
        this.moreMenuEls.delete.disabled = false;
        this.moreMenuEls.copy.disabled = false;
      } else {
        this.moreMenuEls.delete.disabled = true;
        this.moreMenuEls.copy.disabled = true;
      }
    })
    if (this.isEditing) {
      this.innerData(this.recordToEdit);
    }
    this.setMaxBodySize();
    this.mutationObserver = new MutationObserver(this.setMaxBodySize);
    const footerDiv: HTMLElement = this.recordContainer.nativeElement.querySelector('.record-footer')!;
    this.mutationObserver.observe(footerDiv, {
      childList: true,
      subtree: true,
      attributes: true
    });
  }

  ngOnDestroy() {
    screen.orientation.removeEventListener('change', this.setMaxBodySize);
    this.saveRecordSubscription?.unsubscribe();
    this.routeSub.unsubscribe();
    this.inputLength.unsubscribe();
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }
}
