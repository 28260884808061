import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { db } from 'src/app/db/json';
import { Location } from 'src/app/interfaces/archive';
import { CallBack } from 'src/app/interfaces/callback';
import { ApiService } from 'src/app/services/api.service';
import { AuthzService } from 'src/app/services/authz.service';
import { SafeConfigComponent } from './safe-config/safe-config.component';
import { AuthRegionModalComponent } from './region-modal/region-modal.component';
import { bufferToBase64URLString, startRegistration } from '@simplewebauthn/browser';

import { AuthStartModalComponent } from './start-modal/start-modal.component';
import { NgIf } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { startAuthentication } from '@simplewebauthn/browser';
import { PublicKeyCredentialRequestOptionsJSON, RegistrationResponseJSON } from '@simplewebauthn/types';
import { ModalComponent } from "../modal/modal.component";
import { toBase64 } from '@aws-sdk/util-base64-browser'
import { SafeCrypto } from 'src/app/lib/safeCrypto';
import base64url from 'base64url';
import { Console } from 'src/app/lib/console';
import { ErrorService } from 'src/app/services/error.service';
import { Utilities } from 'src/app/lib/utilities';
import { firstValueFrom } from 'rxjs';
import { InitializationService } from 'src/app/services/initialization-service';
import { UserHandleConverter } from 'src/app/lib/userHandleConverter';

@Component({
  selector: 'app-authz',
  templateUrl: './authz.component.html',
  styleUrls: ['./authz.component.scss'],
  standalone: true,
  imports: [NgIf, AuthStartModalComponent,
    SafeConfigComponent,
    AuthRegionModalComponent,
    ModalComponent]
})
/*
* displayModel is initially set to 'start' to display the start modal component that kicks off the whole process.
*/
export class AuthzComponent implements AfterViewInit {

  displayModal = 'start'; // start, SafeConfig, Regions, done. Controles which modal is displayed. 'done' is not a modal but a flag to indicate the process is complete.
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;

  @Output() done = new EventEmitter<void>();
  @Output() started = new EventEmitter<void>();
  callback!: CallBack; // Callback from auth service to be processed
  lastCallback: CallBack | null = null; // Callback from auth service being processed

  flow!: string; // create | access . Flag to indicate if we are creating or accessing a safe. Used by Success modal to trigger post create functions like creating Records storage
  locations!: Location[]; // List of locations for region modal
  private createFailed = false;
  private deviceKey: ArrayBuffer | undefined;
  private passphrase = ''; // passphrase is persisted when accessing a Safe with client ID and passphrase as it is needed to register with webauthn afterwards.
  private FIDO2Success = false;
  keyName: string | undefined;
  accessID = '';
  private action: any;
  private guardNotConfigured = false;
  private noPin = false;

  constructor(private changeDetector: ChangeDetectorRef,
    private authSvc: AuthzService, private apiSvc: ApiService, private translate: TranslateService,
    private db: db, private router: Router, private errorSvc: ErrorService, private initSvc: InitializationService) {
  }

  async ngAfterViewInit(): Promise<void> {
    //Angular path was not playing nice with the hash fragments so we are parsing the hash here directly
    const path = window.location.hash;
    if (path.startsWith('#/register/') || path.startsWith('#/access/')) {
      const parts = path.split('/');
      this.action = parts[1];
      if (parts.length >= 4) {
        this.accessID = base64url.decode(parts[2]);
        this.passphrase = base64url.decode(parts[3]);
        if (parts.length > 4) {
          this.keyName = base64url.decode(parts[4]);
        }
        this.startSelection('access');
      }
    } else if (path.startsWith('#/createFIDO2')) {
      this.startSelection('createFIDO2');
    } else {
      const pid = this.initSvc.getParam('pid');
      if (pid) {
        Console.log('partner id', pid);
        this.startSelection('createFIDO2');
      }
    }
  }

  private setdisplayModal(modal: string): void {
    this.modalController.displaySpinner(false);
    this.displayModal = modal;
    this.changeDetector.detectChanges();
  }

  private async recover() {
    try {
      const code = await this.modalController.displayInput(this.translate.instant('AUTH.RECOVER.CODE.TITLE'), this.translate.instant('AUTH.RECOVER.CODE.MSG'));
      if (code == null) {
        this.relaod();
      }
      Console.log('code', code);
      const parts = code.split('/');
      Console.log('parts', parts);
      if (parts.length < 2 || parts.length > 3 || parts[0].length < 5 || parts[1].length < 5) {
        await this.modalController.displayMessage(this.translate.instant('AUTH.RECOVER.CODE.ERROR.TITLE'), this.translate.instant('AUTH.RECOVER.CODE.ERROR.MSG'));
        this.relaod();
      }
      this.accessID = base64url.decode(parts[0]);
      this.passphrase = base64url.decode(parts[1]);
      if (parts.length > 2) {
        this.keyName = base64url.decode(parts[2]);
      }
      this.startSelection('access');
    } catch (error) {
      Console.error(error);
      await this.errorSvc.process(error);
      setTimeout(() => { location.reload() }, 5000); // reload the page after 5 seconds
    }
  }

  /* Called from start-modal.component.html
  *  to trigger safe access or creation
  *  selected = create | access | accessFIDO2 | createFIDO2 | 'createFIDO2Existing'
  */
  async startSelection(selected: string) {
    Console.log('startSelection', selected);
    this.started.emit(); // Let the main component know we have started so not to display the update available message

    this.listenForAnotherTab();
    if (selected == 'recover') {
      this.recover();
      return;
    }
    this.authSvc.FIDO2 = selected.includes('FIDO2');
    this.flow = selected;

    let args = ''; // tier:promocode
    if (selected == 'createFIDO2') {
      // Is there a promo code and start tier.
      const path = window.location.hash;
      const parts = path.split('/');
      if (parts.length == 3) {
        args = parts[2];
        Console.log('args', args);
      }

      //Test site warning
    //  if (window.location.hostname != 'safe.unolock.com') {
        const msg = await firstValueFrom(this.translate.get('AUTH.TEST'))
        await this.modalController.displayMessage("TEST SITE", msg);
     // }

      //Welcome page
      const title = await firstValueFrom(this.translate.get('AUTH.START.HELP_TITLE')); //wait for translations to be loaded
      const message = await firstValueFrom(this.translate.get('AUTH.START.HELP_MSG'));
      await this.modalController.displayMessage(title, message);
      //Terms and Conditions
      let accepted = false
      do {
        const response = await this.modalController.displayQuestion(this.translate.instant('TERMS.TITLE'), this.translate.instant('TERMS.MSG'), this.translate.instant('CANCEL'), this.translate.instant('TERMS.VIEW'), this.translate.instant('TERMS.ACCEPT'));
        if (response === 'TWO') {
          accepted = true;
        } else if (response === 'ONE') {
          //'View Terms'
          await this.modalController.displayMessage(this.translate.instant('TERMS.TITLE'), this.translate.instant('TERMS.TERMS'));
        } else {
          this.relaod();
        }
      } while (!accepted);
    }
    this.modalController.displaySpinner(true);
    try {
      const start = await this.authSvc.start(selected, args);
      this.process(start);
    } catch (err) {
      Console.error(err);
      await this.errorSvc.process(err);
      setTimeout(() => { location.reload() }, 5000); // reload the page after 5 seconds
    }
  }

  private tablistener = false;
  /**
   * Listen for another tab to be opened and close the current tab if the user is already authorized
   */
  private listenForAnotherTab() {
    //only once
    if (this.tablistener) {
      return;
    } else {
      this.tablistener = true;
    }
    const channel = new BroadcastChannel('tab_channel');

    // Listen for messages
    channel.onmessage = (event) => {
      Console.log('Message received in tab', event.data);
      if (event.data != 'new_tab_opened') {
        if (this.authSvc.isAuthorized()) {
          this.apiSvc.whipeCrypto()
          location.reload(); // Close the current tab
        }
      }
    };

    // Send a message to other tabs when a new tab is opened
    channel.postMessage('new_tab_opened');
  }

  private async process(callback: CallBack) {
    try {
      this.callback = callback;
      Console.log('process', callback);
      const type = callback.type;
      switch (type) {
        case "DowngradeQuestion":
          const answer = await this.modalController.displayQuestion(this.translate.instant("AUTH.DOWNGRADE.TITLE"), this.translate.instant("AUTH.DOWNGRADE.MSG"), null, this.translate.instant("AUTH.DOWNGRADE.NO"), this.translate.instant("AUTH.DOWNGRADE.YES"));
          if (answer == 'TWO') {
            //get credits
            callback.action.result = 'NO';
          } else {
            // Are you sure you want to downgrade?
            const answer = await this.modalController.displayQuestion(this.translate.instant("AUTH.DOWNGRADE.SURE.TITLE"), this.translate.instant("AUTH.DOWNGRADE.SURE.MSG"), null, this.translate.instant("YES"), this.translate.instant("AUTH.DOWNGRADE.YES"));
            if (answer == 'ONE') {
              callback.action.result = 'YES';
            } else {
              callback.action.result = 'NO';
            }
          }
          this.reply(callback);
          break;
        case "ECDHE":
          const request: { pk: any, sig: string } = callback.action.request;
          try {
            const result = await this.authSvc.doKeyExchange(request.pk, request.sig);
            callback.action.result = result;
          } catch (erro) {
            Console.error(erro);
            this.modalController.displaySpinner(true, this.translate.instant('AUTH.KEY.ERROR'));
            setTimeout(() => { location.reload() }, 10000);
            return;
          }
          this.reply(callback);
          break;
        case "WebAuthnCreate":
          // WebAuthn device registration during create
          // server generated accessID is passed in the callback
          this.accessID = callback.action.request.accessID;
          const webAuthnOptions = callback.action.request.options;
          const response = await this.processWebAuthnCreate(webAuthnOptions);
          if (!response) {
            Console.error('WebAuthnCreate failed');
            await this.modalController.displayMessage(this.translate.instant('AUTH.WEBAUTHN.ERROR.TITLE'), this.translate.instant('AUTH.WEBAUTHN.ERROR.MSG'));
            this.relaod();
            return;
          }
          callback.action.result = response;
          this.reply(callback);
          break;
        case "GetWebAuthnConnectonType":
          Console.log('GetWebAuthnConnectonType');
          callback.action.result = 'platform' // 'platform' | 'cross-platform
          const btn = await this.modalController.displayQuestion(this.translate.instant('AUTH.INPUT.SELECT.TITLE'), this.translate.instant('AUTH.INPUT.SELECT.MSG'), this.translate.instant('Cancel'), this.translate.instant('AUTH.INPUT.SELECT.SECURITY_KEY'), this.translate.instant('AUTH.INPUT.SELECT.PASSKEY'));
          if (btn == 'ONE') {
            callback.action.result = 'cross-platform'
          } else if (btn == 'TWO') {
            if (await this.testPasskeySupport()) {
              callback.action.result = 'platform';
            } else {
              await this.modalController.displayMessage(this.translate.instant('AUTH.INPUT.NOT_SUPPORTED.TITLE'), this.translate.instant('AUTH.INPUT.NOT_SUPPORTED.MSG'));
              callback.action.result = 'cross-platform'
            }
          } else {
            this.relaod();
          }

          if (callback.action.result == 'cross-platform') {
            await this.modalController.displayMessage(this.translate.instant('AUTH.INPUT.MOBILE.TITLE'), this.translate.instant('AUTH.INPUT.MOBILE.MSG'));
          }

          this.reply(callback);
          break;
        case "ClientDataKey":
          // Reinitialize the authsvc keyring with the ClientDataMaster key
          const cdk = callback.action.result;
          try {
            await this.authSvc.unwrapAndSetClientDataKey(cdk);
          } catch (err) {
            Console.error("ClientDataKey err", err);
            callback.action.result = 'ERROR';
          }
          this.reply(callback);
          break;
        case "SetClientDataKey":
          // server is requesting the encrypted client data key
          const newMasterKey = await this.authSvc.generateNewClientDataMasterKey();
          callback.action.result = newMasterKey;
          this.reply(callback);
          break;
        case "DecodeKey": // Decode the server Safe Metadata key
          if (this.flow == 'accessFIDO2' || this.flow == 'createFIDO2' || this.flow == 'createFIDO2Existing') {
            //WebAuthn
            //Keyring already initialized with the SafeAccessMasterKey from the Credential Object
            callback.action.result.key = await this.authSvc.unwrapServerMetadataKey(callback.action.request.wrappedKey);
            this.reply(callback);
          } else {
            //accessID and passphrase
            if (!this.passphrase) {
              // Ask owner for passphrase
              this.passphrase = await this.modalController.displayInput(this.translate.instant('AUTH.INPUT.DECODE.TITLE'), this.translate.instant('AUTH.INPUT.DECODE.PLACE'))
              if (this.passphrase == null) {
                this.relaod();
              }
            }
            await this.authSvc.initCryptoWithPassphrase(this.passphrase, this.accessID);
            callback.action.result.key = await this.authSvc.unwrapServerMetadataKey(this.callback.action.request.wrappedKey);
            this.reply(callback);
          }
          break
        case "WebAuthnAuth":
          const authenticatorData = await this.getAuthenticatorData(callback.action.request);
          if (!authenticatorData) {
            //there was an error getting the authenticator data give up.
            return;
          }
          if (authenticatorData.response.userHandle != undefined) {
            {
              //UserHandle is not expected in the response this is a double check for security
              throw new Error('UserHandle should not be in the response');
            }
          }
          callback.action.result = authenticatorData;
          this.reply(callback);
          break;
        case "WebAuthnData":
          const data = callback.action.result;
          await this.processEncryptedCredentialObject(data);
          this.reply(callback);
          break
        case "GetSafeAccessID":
          if (this.flow == 'accessFIDO2' || this.flow == 'createFIDO2' || this.flow == 'createFIDO2Existing') {
            callback.action.result = { accessID: this.accessID };
            this.reply(callback);
          } else {
            if (this.accessID) {
              // AccessID was passed in as a query param
              callback.action.result = { accessID: this.accessID };
              this.reply(callback);
            } else {
              // Ask user for accessID
              const action: string = callback.action.request.action;
              switch (action) {
                case 'open':
                  const accessIDtext = await this.modalController.displayInput(this.translate.instant('AUTH.INPUT.OPEN.TITLE'), this.translate.instant('AUTH.INPUT.OPEN.PLACE'));
                  if (accessIDtext == null) {
                    this.relaod();
                  }
                  this.accessID = await SafeCrypto.hashtoB64(accessIDtext);
                  callback.action.result.accessID = this.accessID;
                  this.reply(callback);
                  break;
                case 'exists':
                  // Called when creating a new safe
                  const newaccessIDtext = await this.modalController.displayInput(this.translate.instant('AUTH.INPUT.EXISTS.FREE.TITLE'), this.translate.instant('AUTH.INPUT.EXISTS.FREE.PLACE'));
                  if (newaccessIDtext == null) {
                    this.relaod();
                  }
                  this.accessID = await SafeCrypto.hashtoB64(newaccessIDtext);
                  callback.action.result.accessID = this.accessID;
                  this.reply(callback);
                  break;
                default:
                  Console.error('Invalid action');
              }
            }
          }
          break;
        case "GetPin":
        case "Captcha":
          const padImage = this.callback.action.request.image;
          const codeImage = this.callback.action.request.codeImage;
          const clicks = await this.modalController.displayPad(type === 'Captcha' ? this.translate.instant('AUTH.PAD.CAP.TITLE') : this.translate.instant('AUTH.PAD.DEF.TITLE'), padImage, codeImage);
          if (clicks === null) { // canceled
            this.setdisplayModal('start');
            return;
          }
          this.callback.action.result = { clicks: clicks };
          this.reply(callback);
          break;
        case 'SafeConfig':
          // Get the safe configuration with the Safe-Config component
          // The Safe-Config component will retuen the updaded callback by calling this.reply function
          if (this.flow == 'createFIDO2Existing') {
            this.keyName = 'UNOLOCK';
          }
          this.setdisplayModal('SafeConfig');
          break;
        case 'SUCCESS':
          this.doSuccess(callback);
          break;
        case 'FAILED':
          this.doFail(callback);
          break;
        default:
          Console.error('Invalid callback type', callback.type);
      }
    } catch (error) {
      Console.error(error);
      this.modalController.displaySpinner(true, this.translate.instant('AUTH.UNKNOWN.ERROR'));
      setTimeout(() => { location.reload() }, 5000); // reload the page after 5 seconds
    }
  }

  private async testPasskeySupport(): Promise<boolean> {
    try {
      if (window.PublicKeyCredential) {
        const available = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
        if (available) {
          Console.log("Supported.");
          return true;
        } else {
          Console.log(
            "WebAuthn supported, Platform Authenticator *not* supported."
          );
          return false;
        }

      } else {
        return false;
      }
    } catch (err) {
      Console.error(err);
      return false;
    }
  }

  /*
   * Sends the callback to the server then calles processes function with the servers response
  */
  async reply(callback: CallBack) {
    this.displayModal = '';
    this.lastCallback = callback;
    this.modalController.displaySpinner(true);
    try {
      this.process(await this.authSvc.reply(callback));
    } catch (erro) {
      Console.error(erro);
      this.modalController.displaySpinner(true, this.translate.instant('AUTH.UNKNOWN.ERROR'));
      setTimeout(() => { location.reload() }, 10000);
    }
  }

  /*
  * Called by <auth-region-modal> after user selects a region to store the data during Safe creation
  */
  async createRecords(location: Location) {
    this.modalController.displaySpinner(true);
    await this.db.initialize(location);
    this.setAuthorized()
  }

  /*
  * Called from the process function when the callback type is SUCCESS
  * Triggers post success functions like creating the Records storage.
  */
  private async doSuccess(callback: CallBack) {
    const messages = callback.action?.message;
    //set tier related data, non fatal if not found
    try {
      let tiersString = messages.find((m: string) => m.startsWith('SAFE_TIERS:'));
      if (tiersString) {
        //trim off the TIERS: prefix
        tiersString = tiersString.substring(11);
        Utilities.setTiers(JSON.parse(tiersString));
      } else {
        Console.error('TIERS not found');
      }
    } catch (err) {
      Console.error('Error getting tier data', err);
    }

    this.authSvc.accessID = this.accessID;
    this.modalController.displaySpinner(true);
    if (this.flow == 'create' || this.flow == 'createFIDO2' || this.createFailed) {
      await this.createInitialRecordArchive();
    } else {
      if (messages.includes('GUARD_INVALID')) {
        //Our lockout guard configuration is no longer valid due to the save expiry date being too close.
        await this.modalController.displayMessage(this.translate.instant('AUTH.GUARD.TITLE'), this.translate.instant('AUTH.GUARD.MSG'));
        setTimeout(() => { this.router.navigate(['/config', { action: "GUARD" }]); }, 3000);
      }
      if (messages.includes('GUARD') || messages.includes('LEGACY')) {
        const isguard = messages.includes('GUARD');
        //Authorized by guard or legacy, need to register a new key
        let msg: string, title: string;
        if (isguard) {
          title = this.translate.instant('AUTH.GUARD.TITLE_GUARD');
          msg = this.translate.instant('AUTH.GUARD.MSG_GUARD');
        } else {
          title = this.translate.instant('AUTH.LEGACY.TITLE');
          msg = this.translate.instant('AUTH.LEGACY.MSG');
        }
        await this.modalController.displayMessage(title, msg);
        this.action = isguard ? 'guard' : 'legacy';
      } else if (messages.includes('NO_GUARD')) {
        //Guard not configured
        this.guardNotConfigured = true;
        if (messages.includes('GUARD_MISSING')) {
          //Guard not configured but legacy is. we may have just recovered by guard
          setTimeout(async () => {
            this.router.navigate(['/config', { action: "GUARD" }]);
          }, 2000);
        }
      }
      if (messages.includes('NO_PIN') && this.flow === 'accessFIDO2') {
        this.noPin = true;
      }
      this.setAuthorized();
    }
  }

  private async doFail(callback: CallBack) {
    Console.log('doFail', callback);
    const messages = callback.action?.message;
    if (messages && messages.length > 0) {
      if (callback.action?.message.includes('LOCKED_TEMP')) {
        this.modalController.displaySpinner(true, this.translate.instant('AUTH.LOCKED.ERROR'));
        setTimeout(() => { location.reload() }, 900000);
        return;
      } else if (callback.action?.message.includes('MISSING_KEY')) {
        await this.modalController.displayMessage(this.translate.instant('AUTH.FIDO_KEY_ERROR.TITLE'), this.translate.instant('AUTH.FIDO_KEY_ERROR'));
        location.reload();
        return;
      } else if (callback.action?.message.includes('INVALID_PROMOCODE')) {
        await this.modalController.displayMessage(this.translate.instant('AUTH.PROMO_ERROR.TITLE'), this.translate.instant('AUTH.PROMO_ERROR.MSG'));
        location.reload();
        return;
      }
    }

    if (!this.FIDO2Success) {
      let message = this.translate.instant('AUTH.UNKNOWN.ERROR');
      let timeout = 15000;
      this.modalController.displaySpinner(true, message);
      setTimeout(() => { window.location.href = window.location.origin }, timeout);
    } else {
      if (this.flow == 'create' || this.flow == 'createFIDO2') {
        Console.log('Create failed', this.lastCallback);
        if (this.lastCallback?.type == 'GetSafeAccessID') {
          this.modalController.displaySpinner(true, this.translate.instant('AUTH.INPUT.EXISTS.TAKEN.PLACE'));
          setTimeout(() => this.startSelection(this.flow), 5000);
        } else if (this.lastCallback?.type == 'Captcha') {
          this.modalController.displaySpinner(true, this.translate.instant('AUTH.INPUT.EXISTS.TAKEN.TITLE'));
          setTimeout(() => this.startSelection('create'), 5000);
        } else {
          this.createFailed = true;
          this.modalController.displaySpinner(true, this.translate.instant('AUTH.TRYAGAIN'));
          setTimeout(() => this.startSelection(this.flow === 'access' ? 'access' : 'accessFIDO2'), 5000);
        }
      } else {
        if (this.FIDO2Success) {
          if (callback.action?.message.includes('MISSING_SAFE')) {
            const answer = await this.modalController.displayQuestion(this.translate.instant("AUTH.INPUT.MISSING.TITLE"), this.translate.instant("AUTH.INPUT.MISSING.MSG"), null, this.translate.instant("YES"), this.translate.instant("NO"));
            if (answer == 'ONE') {
              this.keyName = 'none';
              this.startSelection('createFIDO2Existing');
              return;
            }
          }
        }
        this.FIDO2Success = false;
        await this.modalController.displayMessage(this.translate.instant("AUTH.FAIL.TITLE"), this.translate.instant("AUTH.FAIL.MSG"));
        this.displayModal = 'start';
      }
    }
  }

  /*
  * Called after successful Safe creation to get the storage location from the user and create the initial records archive
  */
  private async createInitialRecordArchive() {
    this.locations = await this.apiSvc.getLocations();
    this.setdisplayModal('Regions');
  }

  private async setAuthorized() {
    this.modalController.displaySpinner(false);
    this.authSvc.setAuthorized(true);

    this.setdisplayModal('done');

    //Trigger WebAuthn register device if action == register
    if (this.action === 'register' || this.action === 'guard' || this.action === 'legacy') {
      const pf = this.passphrase;
      setTimeout(() => { this.registerWebauthnDevice(this.accessID, pf); }, 2000);
    } else {
      this.displayToast();
      this.done.emit();
    }
    this.passphrase = '';
  }

  /**
   * Get the Authenticator Data from the User
   * @param request WebAuthn request
   * @returns
   */
  private async getAuthenticatorData(request: PublicKeyCredentialRequestOptionsJSON) {
    try {
      const authenticatorData = await startAuthentication({ optionsJSON: request });
      let key = authenticatorData.response.userHandle;
      if (!key) {
        throw new Error('No key returned from webauthn');
      }

      this.deviceKey = UserHandleConverter.processUserHandle(key);

      // **** Super important to remove the userHandle from the response as we use it as the data key for decrypting the package recieved from the server that actualy contains our master encryption key. So the server should not see it ****
      authenticatorData.response.userHandle = undefined;
      return authenticatorData;
    } catch (error: any) {
      Console.log(error);
      const response = await this.modalController.displayQuestion(this.translate.instant('AUTH.TRYAGAIN'), this.translate.instant('AUTH.TRYAGAIN_MSG'), this.translate.instant('CANCEL'), this.translate.instant('RETRY'), this.translate.instant('AUTH.START.CREATE'));
      if (response == 'TWO') {
        Console.log('Create new Safe');
        location.hash = '/createFIDO2';
        this.modalController.displaySpinner(true);
        setTimeout(() => { location.reload() }, 500);
        location.reload();

      } else if (response == 'ONE') {
        Console.log('Access existing Safe accessFIDO2');
        this.startSelection('accessFIDO2');
      } else {
        //Cancel
        this.relaod();
      }
    }
    return null;
  }

  /**
   * Decrypt the Credential Object and initialize the keyring with the SafeAccess Master key
   * @param encryptedCredentailObject
   */
  private async processEncryptedCredentialObject(encryptedCredentailObject: any) {
    if (!this.deviceKey) throw new Error('No device key');
    const credentialObject = await SafeCrypto.decryptCredentialObject(encryptedCredentailObject, this.deviceKey);
    this.deviceKey = undefined; // clear the device key as we no longer need it.
    if (!credentialObject.id) throw new Error('No accessID returned from webauthn');
    this.accessID = credentialObject.id;

    //Init keyring with SafeAccessMasterKey
    await this.authSvc.initCryptoWithWebAuthn(credentialObject.pass);
    Console.log('FIDO2 Keyring initialized');
    this.FIDO2Success = true;
  }

  /**
   * Called to create a new Safe using WebAuthn
   * Generates a random passphrase and encrypts the passphrase and accessID with the WebAuthn public key
   * @param registrationOptions
   * @returns
   */
  private async processWebAuthnCreate(registrationOptions: any): Promise<{
    response: RegistrationResponseJSON;
    data: string;
  } | null> {
    try {
      const passphrase = toBase64(window.crypto.getRandomValues(new Uint8Array(32))); // generate a random passphrase
      const SafeAcessMasterKey = await this.authSvc.initCryptoWithPassphrase(passphrase, this.accessID);

      if (!this.keyName) {
        //Get the keyname from the user
        Console.log('keyName', this.action);
        this.keyName = await this.modalController.displayInput(this.translate.instant('AUTH.REGISTER.TITLE'), this.translate.instant('AUTH.REGISTER.MSG'), false);
        if (!this.keyName) {
          this.relaod();
        }
      }
      this.modalController.displaySpinner(true, this.translate.instant('AUTH.WEBAUTHN.SPINNER.MSG'));

      const b64SafeAccessMasterKey = toBase64(Buffer.from(SafeAcessMasterKey));
      const credentialObject = { id: this.accessID, pass: b64SafeAccessMasterKey }
      const data = await this.encryptCredentialsObjectAndUpdateRegistrationOptions(credentialObject, registrationOptions);

      this.modalController.displaySpinner(true);

      const registrationResponse = await startRegistration({ optionsJSON: registrationOptions });
      return ({ response: registrationResponse, data });
    } catch (err) {
      Console.error(err);
      return null;
    }
  }

  /**
   * Register a new device with WebAuthn to an existing Safe
   * At this point we can generated a SafeAccess Master Key with clientid and passphrase
   * keyring is initialized with the Client Data Master key
   * @param accessID From query parameter
   * @param passphrase From query parameter
   */
  private async registerWebauthnDevice(accessID: string, passphrase: string) {
    try {
      this.errorSvc.supressScreenBlur(300);
      const newSafeAccessMasterKey = window.crypto.getRandomValues(new Uint8Array(32));

      const newB64SafeAccessMasterKey = toBase64(Buffer.from(newSafeAccessMasterKey));

      if (!this.keyName) {
        Console.log('keyName2', this.action);
        let msg: string, title: string;
        if (this.action === 'guard') {
          title = this.translate.instant('AUTH.ACCESS.TITLE');
          msg = this.translate.instant('AUTH.ACCESS.MSG');
        } else {
          title = this.translate.instant('AUTH.REGISTER.TITLE');
          msg = this.translate.instant('AUTH.REGISTER.MSG');
        }
        this.keyName = await this.modalController.displayInput(title, msg, false);
      }
      if (!this.keyName) {
        this.relaod();
      }
      // this.action = 'register';
      this.modalController.displaySpinner(true, this.translate.instant('AUTH.WEBAUTHN.SPINNER.MSG'));

      let mode: 'platform' | 'cross-platform' = 'platform';
      const btn = await this.modalController.displayQuestion(this.translate.instant('AUTH.INPUT.SELECT.TITLE'), this.translate.instant('AUTH.INPUT.SELECT.MSG'), this.translate.instant('Cancel'), this.translate.instant('AUTH.INPUT.SELECT.SECURITY_KEY'), this.translate.instant('AUTH.INPUT.SELECT.PASSKEY'));
      if (btn == 'ONE') {
        mode = 'cross-platform'
      } else if (btn == 'TWO') {
        if (await this.testPasskeySupport()) {
          mode = 'platform';
        } else {
          await this.modalController.displayMessage(this.translate.instant('AUTH.INPUT.NOT_SUPPORTED.TITLE'), this.translate.instant('AUTH.INPUT.NOT_SUPPORTED.MSG'));
          mode = 'cross-platform'
        }
      } else {
        this.relaod();
      }

      if (mode == 'cross-platform') {
        await this.modalController.displayMessage(this.translate.instant('AUTH.INPUT.MOBILE.TITLE'), this.translate.instant('AUTH.INPUT.MOBILE.MSG'));
      }

      const registrationOptions = await this.apiSvc.getRegistrationOptions(mode);
      const credentialObject = { id: accessID, pass: newB64SafeAccessMasterKey }
      const encryptedCredentialsObject = await this.encryptCredentialsObjectAndUpdateRegistrationOptions(credentialObject, registrationOptions);

      this.modalController.displaySpinner(true);
      Console.log('registrationOptions', registrationOptions);
      const registrationResponse = await startRegistration({ optionsJSON: registrationOptions });

      await this.apiSvc.sendRegistrationResponse(registrationResponse, encryptedCredentialsObject);

      await this.changeSafeAccessMasterKey(passphrase, accessID, newSafeAccessMasterKey);// only if successful
      await this.modalController.displayMessage(this.translate.instant('AUTH.WEBAUTHN.SUCCESS.TITLE'), this.translate.instant('AUTH.WEBAUTHN.SUCCESS.MSG'));
    } catch (err) {
      Console.log(err);
      await this.modalController.displayMessage(this.translate.instant('AUTH.WEBAUTHN.ERROR.TITLE'), this.translate.instant('AUTH.WEBAUTHN.ERROR.MSG'));
    }
    await this.modalController.displayMessage(this.translate.instant('AUTH.RESTART.TITLE'), this.translate.instant('AUTH.RESTART.MSG'));
    window.location.href = '/';
  }

  /**
   * Generates a new SafeAccessMasterKey and updates the SafeAccess server side with the new wrapped keys.
   * @param passphrase
   * @param accessID
   * @returns
   */
  private async changeSafeAccessMasterKey(passphrase: string, accessID: string, safeAccessMasterKey: Uint8Array): Promise<void> {
    try {
      //Init temp keyring with current SafeAccess Master key by passphrase and clientid
      const crypto = new SafeCrypto(false);
      await crypto.initCryptoWithPassphrase(passphrase, accessID);
      const keys = await this.apiSvc.getServerKeys();

      const serverMetadatakey = keys.server;
      const clientDataMasterkey = await crypto.decryptClientDataMasterKey(keys.client);

      if (!clientDataMasterkey) {
        throw new Error('client data key error');
      }
      //Init keyring with new SafeAccessMasterKey
      const b64SafeAccessMasterKey = toBase64(safeAccessMasterKey);
      await crypto.initCryptoWithSafeAccessMasterKey(b64SafeAccessMasterKey);

      //rewrap the keys with the new SafeAccessMasterKey
      const wrappedServerKey = await crypto.encryptServerMetadataKey(serverMetadatakey);
      const wrappedClientKey = await crypto.encryptClientDataMasterKey(clientDataMasterkey);

      //update the SafeAccess with the new wrapped keys
      const accesses = await this.apiSvc.getAccesses('device guard');
      const access = accesses.find(a => a.accessID === accessID);
      if (!access) {
        throw new Error('access not found');
      }
      access.wrappedClientKey = wrappedClientKey;
      access.wrappedServerKey = wrappedServerKey;

      await this.apiSvc.updateAccess(access);
      Console.log('access updated');
    } catch (err) {
      Console.error(err);
      throw err;
    }
  }

  /**
   * Generates a random device key and encrypts the credential object with the device key.
   * Also updates the registration options with the device key.
   * @param credentialObject
   * @param registrationOptions
   * @returns encrypted CredentailsObject
   */
  private async encryptCredentialsObjectAndUpdateRegistrationOptions(credentialObject: { id: string; pass: string; }, registrationOptions: any): Promise<string> {
    const deviceKey = window.crypto.getRandomValues(new Uint8Array(32))
    const b64DeviceKey = bufferToBase64URLString(deviceKey);

    registrationOptions.user.id = b64DeviceKey; // not sent to the server
    registrationOptions.user.name = this.keyName;
    registrationOptions.user.displayName = this.keyName;

    // import device key
    const deviceCryptoKey = await crypto.subtle.importKey(
      'raw',
      deviceKey,
      'AES-GCM',
      false,
      ['encrypt'],
    );

    const encodedCredentialObject = new TextEncoder().encode(JSON.stringify(credentialObject));

    const iv = window.crypto.getRandomValues(new Uint8Array(16));
    const ciphertext = await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv
      },
      deviceCryptoKey,
      encodedCredentialObject
    );

    const combined = Buffer.concat([iv, Buffer.from(ciphertext)]);
    const encryptedCredentailsObject = toBase64(combined);
    return encryptedCredentailsObject;
  }

  private relaod() {
    window.location.href = window.location.pathname + '#/' // drop query string
    location.reload()
  }

  private displayToast() {
    const newSafe = this.authSvc.newSafe;
    const expired = this.authSvc.expired;
    const currentDate = new Date();
    const safeExpDate = new Date(this.authSvc.safeExpiryEPOCms);
    const daysToExpire = Math.floor((safeExpDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));
    Console.log('daysToExpire', daysToExpire);
    let duration = 5000;
    let pos: "bottom-center" | "bottom-left" | "bottom-right" | "top-left" | "top-center" | "top-right" | undefined = 'bottom-center';
    let backgroundColor = '#323232';
    let textColor = 'white';
    let actionText = this.translate.instant('CLOSE');
    let onActionClick: { (): void; (): void; };
    if (this.apiSvc.getAppConfig().tier == 0) {
      onActionClick = () => { this.router.navigate(['/config', { action: "UPGRADE" }]); };
    } else {
      onActionClick = () => { this.router.navigate(['/config', { action: "CREDITS" }]); };
    }
    let text = '';
    if (expired) {
      Console.log('expired');
      actionText = this.translate.instant('APP_CONFIG.EXTEND');
      text = this.translate.instant('AUTHZSVC.EXPIRED');
    } else if (daysToExpire < 30 || expired) {
      pos = 'top-center';
      duration = 20000;
      backgroundColor = 'red';
      textColor = 'black';
      text = `${this.translate.instant('AUTHZSVC.EXPIRIN')} ${this.timeUntilFutureDate(safeExpDate)}.`;
    } else if (daysToExpire < 60) {
      duration = 5000;
      backgroundColor = 'yellow';
      textColor = 'black';
      actionText = this.translate.instant('APP_CONFIG.EXTEND');
      text = `${this.translate.instant('AUTHZSVC.EXPIRIN')} ${this.timeUntilFutureDate(safeExpDate)}.`;
    } else if (this.noPin) {
      this.noPin = false; //do not leave trace of pin
      duration = 6000;
      backgroundColor = 'yellow';
      textColor = 'black';
      actionText = this.translate.instant('YES');
      text = this.translate.instant('AUTH.NO_PIN.MSG');
      onActionClick = () => { this.router.navigate(['/config', { action: "PIN" }]); };
    } else if (newSafe) {
      duration = 0;
      backgroundColor = '#2d7c5c';
      textColor = 'white';
      actionText = '';
      duration = 10000;
      text = this.translate.instant('AUTHZSVC.NEW_SAFE');
    } else if (this.guardNotConfigured) {
      Console.log('guard not configured');
      text = this.translate.instant('AUTH.GUARD.NOT_CONFIGURED');
      actionText = this.translate.instant('AUTH.GUARD.CONFIGURE');
      onActionClick = () => { this.router.navigate(['/config', { action: "GUARD" }]); };
      backgroundColor = '#323232';
      textColor = 'yellow';
      pos = "top-center";
      duration = 4000;

    } else {
      return;
    }
    Snackbar.show({
      backgroundColor,
      textColor,
      pos,
      text,
      duration,
      actionText,
      onActionClick
    });
  }

  private timeUntilFutureDate(futureDate: Date): string {
    // Check if the futureDate is not in the future and return 'Expired' if true
    if (this.authSvc.expired) {
      return 'Expired';
    }

    // Calculate the difference in milliseconds between currentDate and futureDate
    const currentDate = new Date();
    const diff = futureDate.getTime() - currentDate.getTime();

    // If the difference is less than a day, only calculate hours
    if (diff < (1000 * 60 * 60 * 24)) {
      let hours = Math.floor(diff / (1000 * 60 * 60));
      return `${hours} hour${hours > 1 ? 's' : ''}`;
    }

    // Calculate the difference in years, months, and days
    let years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    let months = Math.floor((diff % (1000 * 60 * 60 * 24 * 365.25)) / (1000 * 60 * 60 * 24 * 30));
    let days = Math.floor((diff % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));

    // Initialize an empty string to store the final output
    let timeString = '';

    // Add the years to the output string if non-zero
    if (years > 0) {
      timeString += `${years} year${years > 1 ? 's' : ''}`;
    }

    // Add the months to the output string if non-zero, with proper formatting
    if (months > 0) {
      if (timeString.length > 0) {
        timeString += ', ';
      }
      timeString += `${months} month${months > 1 ? 's' : ''}`;
    }

    // Add the days to the output string if non-zero, with proper formatting
    if (days > 0) {
      if (timeString.length > 0) {
        timeString += ', ';
      }
      timeString += `${days} day${days > 1 ? 's' : ''}`;
    }
    return timeString;
  }
}
