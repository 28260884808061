<section class="navbar-container">
  <div class="navbar">
    <div class="first-col">
      <div class="pic-container H" (click)="toggleSideBar()">
        <img src="./assets/images/svg/menu.svg" class="pic" alt="menu">
      </div>
      <div class="unolock-img">
        <img src="./assets/images/Unolock-Logo_black.svg" width="110" alt="UnoLock">
      </div>
    </div>
    <div class="sec-col">
      <div class="btns-container">
        <div class="view H hide-on-small-screens"
          (click)="toggleView()"></div>
      </div>
      <div (click)="getCredits()" class="date-container H">
        <div class="wide-screen-div tierName">{{getTierName()}}<span>&nbsp;</span></div>
        <div class="wide-screen-div">{{'NAV_BAR.EXPIRY_DATE' | translate}}<span>&nbsp;</span>|<span>&nbsp;</span></div>
        <div *ngIf=" authSvc.safeExpiryEPOCms" class="date">{{ authSvc.safeExpiryEPOCms | date : 'mediumDate' : '' :
          translate.currentLang }}</div>
      </div>
      <img *ngIf="authSvc.appConfig.ro" src="./assets/images/svg/lock-locked.svg" alt="pin" class="pic navlocked"
        alt="Safe Locked" (click)="unlock()">
      <div class="btns-container">
        <div *ngIf="authSvc.appConfig.tier == 2 || authSvc.appConfig.tier == 3" class="refresh H pop"
          [attr.data-pop]="('NAV_BAR.REFRESH' | translate )" (click)="refresh()"></div>
        <div class="close H"  (click)="close()"></div>
      </div>
    </div>
  </div>
</section>

<app-modals></app-modals>
