<div class="main">
  <div>
    <app-modals></app-modals>
    <div class="button-container">
      <button class="btn-primary" (click)="createArchive()" [disabled]="readonly" >
        {{ 'ARCHIVES.NEW_FILE' | translate }}
      </button>
      <div *ngIf="haveLocal">
        <label for="input" id="fileInputLabel" class="btn-primary" >
          {{'ARCHIVES.OPEN' | translate}}
        </label>
        <input id="input" #input type="file" accept=".ulf" (change)="onFileSelected($event)">
      </div>
    </div>
  </div>

  <app-filedrop *ngIf="!readonly"  (fileDropped)="onFileDropped($event)"></app-filedrop>
  <div class="archiveList">
    <div *ngFor="let archive of archives" (click)="selectArchive(archive)">
      <div class="archive_location">
        <img *ngIf="archive.t == 'Local'" src="./assets/images/svg/storage.svg" alt="Register Key" class="pic">
        <img *ngIf="archive.t == 'Cloud'" src="./assets/images/svg/cloud.svg" alt="Register Key" class="pic">
        <img *ngIf="archive.recordId" src="./assets/images/svg/file.svg" alt="record" class="pic record" (click)="openRecord(archive.recordId)">
      </div>
      <div class="archive_name">
        {{ archive.m.name}}
      </div>
      <div *ngIf="wide" class="archive_type">
        {{ archive.m.type }}
      </div>
    </div>
  </div>
</div>
