<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="langselect">
        <select appHelp="AUTH.START.HELP" #langSelect (change)="changeLanguage(langSelect.value)"
          [(ngModel)]="currentLang">
          <option class="notranslate" *ngFor="let lang of translate.getLangs()" [value]="lang">{{ lang }}</option>
        </select>
      </div>
      <div class="auth-container">
        <div class="img-container">
          <img [src]="imageSrc" alt="Unolock" />
        </div>
        <div class="auth-title" *ngIf="!supported" [innerHTML]="warning"></div>
      </div>
      <div class="button-container ">
        <button [disabled]="!supported" class="btn-primary openbtn" (click)="buttonClick('accessFIDO2')"> {{ 'AUTH.START.ACCESS'
          |
          translate }} </button>
      </div>

      <div class="button-container others">
        <button [disabled]="!supported" class="btn" (click)="buttonClick('createFIDO2')"> {{ 'AUTH.START.CREATE' |
          translate }} </button>
          <button [disabled]="!supported" class="btn" (click)="buttonClick('recover')"> {{ 'AUTH.START.RECOVER' | translate
          }} </button>
      </div>
    </div>
  </div>
</div>
