import { NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Archive } from 'src/app/interfaces/archive';
import { ApiService } from 'src/app/services/api.service';
import { ErrorService } from 'src/app/services/error.service';
import { ModalComponent } from "../../modal/modal.component";
import { RecordsService } from 'src/app/services/records.service';
import { Subscription, filter } from 'rxjs';
import { FileService } from 'src/app/services/file.service';
import { FiledropComponent } from "./filedrop/filedrop.component";
import { Console } from 'src/app/lib/console';

@Component({
  selector: 'app-archives',
  templateUrl: './archives.component.html',
  styleUrls: ['./archives.component.scss'],
  standalone: true,
  imports: [NgFor, TranslateModule, ModalComponent, NgIf, FiledropComponent]
})
export class ArchivesComponent implements OnInit, OnDestroy {

  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;
  archives: Archive[] = [];
  readonly = false;
  private routerSubscription: Subscription;
  openFile = false;
  wide = false;
  haveLocal = false;
  spaceIDSubscription: any;

  constructor(private apiSvc: ApiService, public translate: TranslateService, private router: Router, private recordsSvc: RecordsService, private fileSvc: FileService) {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((_event: any) => {
        this.loadData();
      });
    this.wide = window.innerWidth > 600;
  }

  ngOnInit(): void {
    // Subscribe to spaceID changes
    this.spaceIDSubscription = this.apiSvc.spaceID$.subscribe((newID) => {
      Console.log('spaceID changed to:', newID);
      this.loadData(newID);
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.spaceIDSubscription) {
      this.spaceIDSubscription.unsubscribe();
    }
  }

  private async loadData(spaceID = this.apiSvc.spaceID) {
    this.archives = [];
    this.readonly = this.apiSvc.getAppConfig().ro;
    //only add archives that have t = Cloud or Local
    let archives = (await this.apiSvc.getArchives()).filter(a => a.t == 'Cloud' || a.t == 'Local');
  //  Console.log('All Archives', archives);

    //ToDo: Remove this after all archives have a space id
    if (!this.readonly) {
      for (const archive of archives) {
        if (!archive.sid) {
          archive.sid = spaceID;
          await this.apiSvc.updateArchive(archive);
        }
      }
    }

    archives = archives.filter(a => a.sid == spaceID); //only show archives for this space
   // Console.log('Filtered Archives in this space', archives);
    this.haveLocal = archives.some(a => a.t == 'Local');

    this.archives.push(...archives);
    for (const archive of this.archives) {
      const record = this.recordsSvc.getRecordByArchiveID(archive.id);
      if (record) {
        archive.recordId = record.id;
      }
    }
  }

  selectArchive(archive: Archive) {
    const id = archive.id;
    this.router.navigate(['/archives', id]);
  }

  async createArchive() {
    const archive = await this.modalController.displayFileUploader();
    if (!archive) return;
    this.archives.push(archive);
  }

  async openLocal() {
    this.openFile = true
  }

  // Used to open a local file form a ULF file
  async onFileSelected(event: any) {
    this.openFile = false;
    const files = event.target.files;
    if (files.length > 1) {
      this.modalController.displayMessage(this.translate.instant('RECORDS.SINGLE_ULF.TITLE'), this.translate.instant('RECORDS.SINGLE_ULF.MSG'));
      return;
    }
    const file = files[0];
    try {
      const archiveID = await this.fileSvc.initFetchLocalFile(file);
      this.router.navigate(['/archives', archiveID], { state: { file } });

    } catch (err) {
      ErrorService.instance.process(err);
    }
  }

  async onFileDropped(files: File[]) {
    Console.log('Files Dropped', files);
    //sort files into ulf and non-ulf
    const ulfs: File[] = [];
    const nonulfs: File[] = [];
    for (const file of files) {
      if (file.name.endsWith('.ulf')) {
        ulfs.push(file);
      } else {
        nonulfs.push(file);
      }
    }
    Console.log('ULFS', ulfs);
    Console.log('NONULFS', nonulfs);
    // Do non-ulf files first as ulf might trigger a navigation if onlu one ulf is dropped
    if (nonulfs.length > 0) {
      for (const file of nonulfs) {
        Console.log('Calling DiplayFileUploader', file);
        const archive = await this.modalController.displayFileUploader(file);
        Console.log('DiplayFileUploader Archive', archive);
        if (!archive) return;
        this.archives.push(archive);
      }
    }

    if (ulfs.length > 0) {
      await this.onFileSelected({ target: { files: ulfs } });
    }
  }

  async deleteArchive(archive: Archive) {
    const result = await this.modalController.displayQuestion(this.translate.instant('RECORDS.DELETE'), this.translate.instant('RECORDS.DELETE_CONFIRMATION'), this.translate.instant('CANCEL'), null, this.translate.instant('RECORDS.DELETE'));
    if (result != 'TWO') {
      return
    }
    this.archives.splice(this.archives.indexOf(archive), 1);
    await this.apiSvc.deleteArchive(archive.id);

    //remove and references to this archive in records
    this.recordsSvc.removeArchive(archive.id);
  }

  openRecord(recordId: number) {
    this.router.navigate(['/records', recordId]);
  }
}
