import { CoinType } from "./paperWallet";

export class CryptoUnitConverter {

  public static toLargestUnit(coinType: CoinType, value: bigint): string {

    const decimals = this.getCoinDecimals(coinType);
    const divisor = 10n ** BigInt(decimals); // Divisor is 10^decimals
    const wholePart = value / divisor;     // Integer division to get the whole part
    const fractionalPart = value % divisor; // Remainder to get the fractional part

    // Pad the fractional part with leading zeros to ensure it matches the expected decimal places
    return (
      wholePart.toString() +
      '.' +
      fractionalPart.toString().padStart(decimals, '0')
    );
  }

  public static toSmallestUnit(coinType: CoinType, value: string): bigint {

    const decimals = this.getCoinDecimals(coinType);
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      throw new Error("Invalid numeric value");
    }

    return BigInt(Math.round(numericValue * 10 ** decimals)); // Ensures precision
  }

  private static getCoinDecimals(coinType: CoinType): number {
    switch (coinType) {
      case CoinType.BTC:
        return 8;
      case CoinType.USDT:
      case CoinType.USDC:
        return 6;
      case CoinType.DAI:
      case CoinType.BNB:
      case CoinType.LINK:
      case CoinType.ETH:
        return 18;
      case CoinType.LTC:
        return 8;
      case CoinType.DOGE:
        return 8;
      case CoinType.SOL:
        return 9;
      default:
        throw new Error(`Unsupported coin type: ${coinType}`);
    }
  }
}
