import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QrCodeComponent } from 'src/app/components/qrcode/qrcode.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SafeCrypto } from 'src/app/lib/safeCrypto';
import { ApiService } from 'src/app/services/api.service';
import { ErrorService, ErrorType, SafeError } from 'src/app/services/error.service';
import { SafeAccess } from 'src/app/interfaces/safeAccess';
import { NgIf } from '@angular/common';
import base64url from 'base64url';
import { AuthzService } from 'src/app/services/authz.service';
import { Console } from 'src/app/lib/console';
import { PrintService } from 'src/app/services/print.service';
import { MessageModalComponent } from "../message-modal/message-modal.component";
import { CopyTextDirective } from 'src/app/components/copyDirective/copy-text.directive';
import { State } from '../state';
import { QuestionModalComponent } from "../question-modal/question-modal.component";

@Component({
  selector: 'accessid-modal',
  templateUrl: './access-id.component.html',
  styleUrls: ['./access-id.component.scss'],
  standalone: true,
  imports: [QrCodeComponent, TranslateModule, NgIf, MessageModalComponent, CopyTextDirective, QuestionModalComponent]
})
export class AccessidModalComponent implements OnInit {

  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>
  @ViewChild("authBodyInput") inputElement!: ElementRef<HTMLInputElement>
  @Output() response = new EventEmitter<boolean>();

  @Input() data!: { title: string, action: 'access' | 'register', passphrase: string, access: SafeAccess, keyName?: string };

  url = '';
  title = '';
  private encodedPassphrase = '';
  private encodedAccessID = '';
  code = '';
  private success = true;

  constructor(private apiSvc: ApiService, private errorSvc: ErrorService, public authzSvc: AuthzService, private translate: TranslateService, private printService: PrintService) {
    this.title = this.translate.instant('MODALS.ACCESS_ID.REAUTHZ');
  }

  async ngOnInit(): Promise<void> {
    try {

      const access = this.data.access;
      Console.log('AccessID', access.accessID);
      Console.log('passphrase', this.data.passphrase);
      this.encodedPassphrase = base64url.encode(this.data.passphrase);
      this.encodedAccessID = base64url.encode(access.accessID);

      if (this.data.keyName) {
        this.code = `${this.encodedAccessID}/${this.encodedPassphrase}/${this.data.keyName}`;
      } else {
        this.code = `${this.encodedAccessID}/${this.encodedPassphrase}`;
        Console.log('Code', this.code);
      }

      const keys = await this.calculateKeysByPassphrase(this.data.passphrase, access.accessID);
      if (!keys) {
        this.errorSvc.process('Error calculating keys');
        this.response.emit(false);
        return;
      }
      access.wrappedClientKey = keys.wrappedClientKey;
      access.wrappedServerKey = keys.wrappedServerKey;
      await this.apiSvc.updateAccess(this.data.access);
      this.title = this.data.title;
      this.url = `${window.location.origin}/#/${this.data.action}/${this.encodedAccessID}/${this.encodedPassphrase}`; // path fragments are not sent to the server
      if (this.data.keyName) {
        this.url += `/${this.data.keyName}`;
        this.code = base64url.encode(`${this.encodedAccessID}/${this.encodedPassphrase}/${this.data.keyName}`);
      }

    } catch (err: any) {
      Console.error(err);
      const errormsg = err.message || "Unkown";
      this.errorSvc.process(new SafeError(ErrorType.UNKNOWN, this.translate.instant('MODALS.ACCESS_ID.ERROR_MSG') + errormsg));
      this.response.emit(false);
    }
  }

  next() {
    this.response.emit(this.success);
  }

  /**
   *
   * @param passphrase Get the keys from the server and encrypt them with the passphrase
   * @param accessID
   * @returns
   */
  private async calculateKeysByPassphrase(passphrase: string, accessID: string) {
    const keys = await this.apiSvc.getServerKeys(); // keys are encrypted with the current safeaccess master key
    const serverMetadatakey = keys.server;
    const clientDataMasterkey = await this.decryptClientDataMasterKey(keys.client);
    if (!clientDataMasterkey) {
      this.success = false;
      throw new Error('client key not found');
    }
    //Init keyring with passphrase and clientid
    const crypto = new SafeCrypto(false);
    await crypto.initCryptoWithPassphrase(passphrase, accessID);
    const wrappedServerKey = await crypto.encryptServerMetadataKey(serverMetadatakey);
    const wrappedClientKey = await crypto.encryptClientDataMasterKey(clientDataMasterkey);

    return { wrappedServerKey, wrappedClientKey };
  }

  // Get the clientDataKey from the authorization service, or
  // if it doesn't exist, get the clientDataKey from the API service
  private async decryptClientDataMasterKey(wrappedClientDataKey: string) {
    const credentialObject = await this.apiSvc.getCredentialObject();

    if (!credentialObject) {
      this.success = false;
      throw new Error('No data returned from webauthn');
    }
    const crypto = new SafeCrypto(false);

    await crypto.initCryptoWithSafeAccessMasterKey(credentialObject.pass);
    const clientDataMasterKey = await crypto.decryptClientDataMasterKey(wrappedClientDataKey);
    return clientDataMasterKey;
  }

  print() {
    this.printService.print('print-container');
  }

  copyCode() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.code);
    }
  }

  copyURL() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.url);
    }
  }


  //----------- Display Message -----------------------------------

  showMessage = false;
  messageData!: { title: string, message: string };
  private messageResolver: { resolve: Function, reject: Function } | null = null;
  public async displayMessage(title: string, message: string): Promise<void> {

    this.messageData = { title, message };
    return new Promise<void>((resolve, reject) => {
      this.messageResolver = { resolve, reject };
      this.showMessage = true;
    });
  }

  // Called by modal from html
  async messageReturn() {
    this.showMessage = false;
    if (this.messageResolver) this.messageResolver.resolve();
    this.messageResolver = null;
  }

  //--------------- Display Question -------------------------------

  showQuestion = false;
  questionData!: { title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, btnTwoLable: string | null };
  private questionResolver: { resolve: Function, reject: Function } | null = null;
  /**
  returns 'ZERO' if first button is pressed
  returns 'ONE' if second button is pressed
  returns 'TWO' if third button is pressed
  */
  public async displayQuestion(title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, btnTwoLable: string | null): Promise<string> {

    this.questionData = { title, message, btnCancelLable, btnOneLable, btnTwoLable };
    return new Promise<string>((resolve, reject) => {
      this.questionResolver = { resolve, reject };
      this.showQuestion = true;
    });
  }

  // Called by modal from html
  async questionReturn(state: State) {
    this.showQuestion = false;
    const button = state.value;
    if (this.questionResolver) this.questionResolver.resolve(button);
    this.questionResolver = null;
  }

}

