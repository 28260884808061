<div #appConfigModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div class="auth-title">
          <p>{{title}}</p>
        </div>
        <div class="info">
          <div> {{'NAV_BAR.VERSION' | translate}}{{version}}</div>
          <div class="view-licenses-btn" routerLink="/licenses"> {{'NAV_BAR.LICENSES' | translate}} </div>
        </div>
        <br>
        <div class="checkbox-container">
          <dir class="item">
            <dir class="checkboxes" appHelp="FEATURES.HIDE">
              <input type="checkbox" id="hide" name="hide" [(ngModel)]="config.hide" (change)="cbChange()">
              <label for="hide">{{ 'FEATURES.HIDE' | translate}}</label><br>
            </dir>
          </dir>
          <dir class="item" *ngIf="!config.guardSet">
            <dir class="checkboxes" appHelp="FEATURES.GUARD">
              <input type="checkbox" id="guard" name="guard" [(ngModel)]="config.hg" (change)="cbChange()">
              <label for="guard">{{ 'FEATURES.GUARD' | translate}}</label><br>
            </dir>
          </dir>
          <dir class="item">
            <dir class="checkboxes" appHelp="FEATURES.AFFILIATE">
              <input [disabled]="config.tier!=2 && config.tier!=3 || config.aff || !isAdmin" type="checkbox" id="affiliate"
                name="affiliate" [(ngModel)]="config.aff" (change)="cbChange()">
              <label for="affiliate">{{ 'FEATURES.AFFILIATE' | translate}}</label><br>
            </dir>
          </dir>
        </div>
        <div class="button-container">
          <button  [disabled]="ro" class="btn delete-btn" (click)="deleteSafe()">{{ 'NAV_BAR.DELETE_SAFE' | translate }}</button>
        </div>
        <div class="button-container">
          <button [ngClass]="!changed ? 'btn-primary': 'btn'" (click)="cancel()"> {{cancelBtnTxt}} </button>
          <button [disabled]="!changed" class="btn-primary" (click)="save()"> {{saveBtnTxt}} </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modals></app-modals>
