
import { AddressType, CoinType, NetworkType } from './paperWallet';
import { Console } from './console';

export class Wallet {

  static fromJSON(json: any): Wallet {
    if (!json.savedAddresses) {
      json.savedAddresses = [];
    }
    const wallet = new Wallet(json.address, json.type, json.network, json.coinType, json.mnemonic, json.savedAddresses);
    wallet.privateKey = json.privateKey;
    return wallet;
  }

  static toJSON(wallet: Wallet): any {
    return {
      address: wallet.address,
      privateKey: wallet.privateKey,
      type: wallet.type,
      network: wallet.network,
      coinType: wallet.coinType,
      mnemonic: wallet.mnemonic,
      savedAddresses: wallet.savedAddresses
    };
  }

  privateKey = '';
  constructor(
    public address: string,
    public type: AddressType,
    public network: NetworkType,
    public coinType: CoinType,
    public mnemonic: string,
    public savedAddresses: string[] = []
  ) {
    if (type === AddressType.BitcoinORIG) {
      this.type = AddressType.Bitcoin; // backwards compatibility
    }
  }

  //Encrypts the private key client side and then serverside to force the requierment of webauthn for private key access
  setPrivateKey(privateKey: string) {
    Console.log('Setting private key:', privateKey);
    this.privateKey = privateKey;
  }

  public getsmallUnitName() {

    switch (this.coinType) {
      case CoinType.BTC:
        return 'satoshi';
      case CoinType.ETH:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
      case CoinType.BNB:
      case CoinType.LINK:
        return 'wei';
      case CoinType.LTC:
        return 'litoshi';
      case CoinType.DOGE:
        return 'shibes';
      case CoinType.ADA:
        return 'lovelace';
      case CoinType.SOL:
        return 'lamport';
      // case CoinType.XRP:
      //   return 'drop';
      default:
        return 'unit';
    }
  }

  getIcon() {
    return CoinType.getSvgPath(this.coinType);
  }

  getSmallIcon(): string {
    return CoinType.getSmallSvgPath(this.coinType);
  }
}
