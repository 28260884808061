import { NgIf} from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Archive } from 'src/app/interfaces/archive';
import { FormsModule } from '@angular/forms';
import { ErrorService } from 'src/app/services/error.service';
import { State } from './state';
import { UploadFileSelectedState } from './uploadFileSelectedState';
import { CompletedState } from './completedState';
import { UploadingState } from './uploadingState';
import { StartState } from './startState';
import { ApiService } from 'src/app/services/api.service';
import { AuthzService } from 'src/app/services/authz.service';
import { FileService } from 'src/app/services/file.service';
import { Console } from 'src/app/lib/console';
import { FreeStartState } from './freeStartState';
import { Platform } from '@angular/cdk/platform';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'file-uploader-modal',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  standalone: true,
  imports: [TranslateModule, NgIf, FormsModule],
  providers: [UploadFileSelectedState, UploadingState, CompletedState]
})

// Uses state pattern to manage the UI. Each state controls the UI for a specific step in the process and the state transitions to the next state.
// All States extend the abstract class AbstractState that implements State.
// This Component is the model for the modal dialog. It is passed to each state so that the state can control the UI.
// State change is initiated by calling model.setState(<<State>>) with the next state instance.
// The model is set in the State in the AbstractState setModel() method which calls the abstract async init() method.

// The states are:
// StartState - Initial state. No file selected.
// UploadFileSelectedState - File selected. Local or Remote storage selected.
// UploadingState - File uploading.
// CompletedState - File uploaded. Archive returned.

export class FileUploadComponent implements AfterViewInit, OnInit, OnDestroy {
  public ios = this.platform.IOS || this.platform.SAFARI || this.platform.WEBKIT || this.platform.FIREFOX; // ya... I know
  public mobile = this.platform.ANDROID || this.platform.IOS || this.platform.WEBKIT;
  public isIOSStandalone = false;

  //Shared by the States
  public archive: Archive | null = null;
  public state!: State;
  public fileName: string | null = null;
  public showFileName = false;

  private _file: File | null = null;
  private keepalive: any
  @ViewChild("input") inputElement!: ElementRef<HTMLInputElement>
  @Output() public response = new EventEmitter<Archive | null>();


  @Input()
  set file(value: any) {
    if (this._file) {
      Console.log('FileUploadComponent set file: another file', this._file);
      this._file = value;
      //Reset the model for the next upload
      this.archive = null;
      this.fileName = null;
      this.showFileName = false;
      //Storage location already selected
      if (this.local) {
        this.state = new UploadingState();
      } else {
        this.state = new UploadFileSelectedState();
      }
      this.state.setModal(this);
    } else {
      this._file = value;
      Console.log('FileUploadComponent set first file', this._file);
    }
  }
  get file(): any {
    return this._file;
  }

  public local = false;

  constructor(public translate: TranslateService, public changeDetector: ChangeDetectorRef, public errorSvc: ErrorService, public apiSvc: ApiService, public authzSvc: AuthzService, public fileSvc: FileService, private platform: Platform, public http: HttpClient) {
    this.isIOSStandalone = this.ios &&
      (navigator as any).standalone === true
  }

  ngOnInit(): void {
    this.keepalive = setInterval(() => {
      this.authzSvc.ping();
    }, 60000);

    if (this.authzSvc.appConfig.tier == 0 || this.authzSvc.appConfig.tier == 4) {
      this.state = new FreeStartState();
    } else {
      this.state = new StartState();
    }
    this.state.setModal(this);
  }

  ngOnDestroy(): void {
    clearInterval(this.keepalive);
  }

  ngAfterViewInit(): void {
    Console.log('FileUploadComponent ngAfterViewInit', this.file);
    if (!this.file) {
      this.inputElement.nativeElement.click();
    }
  }

  public async setState(state: State) {
    Console.log('Setting state', state);
    this.state = state;
    await this.state.setModal(this);
    this.changeDetector.detectChanges();
  }

  //Called by states to return to the parent component
  public async respond(archive: Archive | null) {
    Console.log('FileUploadComponent respond', archive);
    this.response.emit(archive);
  }
}
