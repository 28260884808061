<div *ngIf="address" #authModalContainer class="modal-container">
  <div #authModal id="walletModal" class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div id='print-container'>
          <div class="title">
            <div class="title" [ngClass]="{'address': !showPrivateKey, 'privatekey': showPrivateKey}">
              <img [src]="smallImageURL" />
              <div class="auth-title" [innerHTML]="title" appHelp="MODALS.WALLET.HELP"></div>
            </div>
            <span [ngClass]="{'closeaddress': !showPrivateKey, 'closeprivatekey': showPrivateKey}"
              (click)="next()">X</span>
          </div>
          <div *ngIf="!readonly" class="button-container">
            <button class="btn toggle" (click)="toggle()">{{btnText}}</button>
          </div>


          <!-- QR Code display -->
          <div *ngIf="!showPrivateKey" class="qrcode">
            <qrcode *ngIf="address" appCopyText [copyText]="address" [level]="'H'" [size]='300' [value]="address">
            </qrcode>
          </div>

          <!-- Mnemonics display -->
          <div *ngIf="showPrivateKey" class="mnemonic-container">
            <div *ngFor="let word of mnemonicWords; let i = index" class="mnemonic-word" [attr.data-index]="i + 1">
              {{ word }}
            </div>
          </div>

          <div *ngIf="address" #authBody class="auth-body">
            <div class="textHolder notranslate" autocorrect="off" autocapitalize="off" spellcheck="false"
              autocomplete="off">
              <div *ngIf="imageURL" class="bitcoinimage">
                <img *ngIf="showPrivateKey" [src]="imageURL" />

                <!-- <p *ngIf="!balance" class="p-wrap">{{'MODALS.WALLET.TYPE' | translate }}{{wallet.coinType}} </p> -->
                <div *ngIf="!showPrivateKey" class="button-container">
                  <button *ngIf="!balance" class="btn toggle" (click)="getbalance()">{{'MODALS.WALLET.BALANCE'|
                    translate}}</button>
                  <span *ngIf="ethereumBalance">Eth:&nbsp;</span>
                  <span *ngIf="ethereumBalance">{{ethereumBalance}}&nbsp;</span>
                  <span *ngIf="ethereumBalance">{{wallet.coinType}}:&nbsp;</span>
                  <span *ngIf="balance">{{balance}}</span>
                </div>
              </div>
              <div class="address-container">
                <img appCopyText [copyText]="address" src="./assets/images/svg/copy.svg" alt="Copy" class="copy" />
                <p appCopyText [copyText]="address" class="p-wrap">{{address}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="button-container">
          <div *ngIf="!showPrivateKey || readonly">
            <button class="btn" (click)="print()">{{'MODALS.WALLET.PRINT' | translate }}</button>
          </div>
          <div *ngIf="showPrivateKey && ! readonly">
            <button appCopyText [copyText]="mnemonicWords.join(' ')" class="btn" >{{'COPY' | translate }}</button>
          </div>
          <button class="btn-primary" (click)="next()"> {{ 'CLOSE' | translate }} </button>
        </div>
      </div>
    </div>
  </div>
</div>
<question-modal #questionemodal *ngIf="showQuestion" [data]="questionData"
  (response)="questionReturn($event)"></question-modal>
<coin-selector-modal #coinSelectorModal *ngIf="showCoinSelector"
  (response)="coinSelectorReturn($event)"></coin-selector-modal>
<input-modal *ngIf="showInput" [data]="inputData" (response)="inputReturn($event)"></input-modal>
