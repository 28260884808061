<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <h2 class="auth-title" [innerHTML]="data.title"></h2>
        <div #authBody class="input-body">
          <div [innerHTML]="message" class="notranslate"></div>
          <div>
            <div class="ta">
              <textarea name="displayValue" tabindex="1" #authBodyInput [value]="displayValue"
                (input)="onInputChange(); adjustRows(authBodyInput)" class="textHolder notranslate" type="text"
                autofocus autocorrect="off" autocapitalize="off" spellcheck="false" autocomplete="off" rows="1"
                maxlength="100">
              </textarea>
            </div>
            <div *ngIf="data.isPasswd" class="pwcheckbox">
              <label for="showPW">Show</label><br>
              <input autofocus class="notranslate" autocorrect="off" autocapitalize="off" spellcheck="false" autocomplete="off" name="showPassphrase" tabindex="4" type="checkbox" id="showPW" [(ngModel)]="showPassphrase"
                (change)="toggleVisibility()">
            </div>
          </div>
          <div #buttonContainer class="button-container">
            <button tabindex="4" class="btn" (click)="cancel()"> {{ 'CANCEL' | translate
              }}</button>
            <button tabindex="3" class="btn" (click)="reset()"> {{ 'RESET' | translate
              }}</button>
            <button tabindex="2" [disabled]="realValue==''" class="btn-primary" (click)="send()"> {{ 'NEXT' | translate
              }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
