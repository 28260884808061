import { NgIf } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SafeAccess } from 'src/app/interfaces/safeAccess';
import { ApiService } from 'src/app/services/api.service';
import { SafeCrypto } from 'src/app/lib/safeCrypto';
import { ModalComponent } from "../../../modal/modal.component";
import { ErrorService } from 'src/app/services/error.service';
import { FormsModule } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { Utilities } from 'src/app/lib/utilities';
import { Console } from 'src/app/lib/console';

@Component({
  selector: 'app-legacylink-editor',
  templateUrl: './legacylink-editor.component.html',
  styleUrls: ['./legacylink-editor.component.scss'],
  standalone: true,
  imports: [TranslateModule, NgIf, ModalComponent, FormsModule, TranslateModule]
})
export class LegacyLinkEditorComponent implements AfterViewInit {
  @Output() onChange: EventEmitter<SafeAccess> = new EventEmitter();
  @Input() legacyAccess!: SafeAccess | undefined;
  @Input() guardAccess!: SafeAccess;
  @Input() legacyDelayMax!: number;
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;

  constructor(private apiSvc: ApiService, public translate: TranslateService, private errorSvc: ErrorService, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
    Console.log('LegacyLinkEditorComponent.ngAfterViewInit', this.legacyAccess, this.guardAccess, this.legacyDelayMax);
  }

  public async createAccess() {
    try {
      if (this.legacyAccess) {
        await this.apiSvc.deleteAccess(this.legacyAccess.accessID);
        this.guardAccess.legacyId = '';
        this.legacyAccess = undefined;
      }

      if (this.legacyDelayMax < 1) {
        this.modalController.displayMessage(this.translate.instant('GUARD.EXPIRY.TITLE'), this.translate.instant('GUARD.EXPIRY.MSG'));
        this.onChange.emit(undefined);
        return;
      }

      const defaultMonths = Math.min(12, this.legacyDelayMax);
      let value = await this.modalController.displayNumber(this.translate.instant('GUARD.LEGACY.TRIGGER_MSG.TITLE'), this.translate.instant('GUARD.LEGACY.TRIGGER_MSG.MSG'), this.translate.instant('MODALS.NUMBER.MONTHS'), defaultMonths, 1, this.legacyDelayMax, 1);
      if (value == null) {
        this.delete();
        this.onChange.emit(undefined);
        return;
      }

      const legacyAccess = new SafeAccess();
      legacyAccess.type = 'l';
      legacyAccess.enabled = false;
      this.guardAccess.legacyDelay = value;
      do {
        try {
          legacyAccess.accessID = uuidv4();;
          this.modalController.displaySpinner(true);
          await this.apiSvc.createAccess(legacyAccess);
        } catch (error) {
          legacyAccess.accessID = '';
        }
      } while (!legacyAccess.accessID);

      //Create accessID and display QRCode
      const passphrase = Utilities.generateSecurePassword();

      const success = await this.modalController.displayAccessID(this.translate.instant('GUARD.NOTIFICATION.REGISTRATION.SUCCESS.QRCODE'), 'access', passphrase, legacyAccess);
      if (!success) {
        //cleanup
        await this.apiSvc.deleteAccess(legacyAccess.accessID);
        this.guardAccess.legacyId = '';
        this.onChange.emit(undefined);
        return;
      }
      this.guardAccess.legacyId = legacyAccess.accessID;
      this.legacyAccess = legacyAccess;
      await this.apiSvc.updateAccess(this.guardAccess);
      await this.modalController.displayMessage(this.translate.instant('SUCCESS'), this.translate.instant('GUARD.LEGACY.MSG1', { months: value }));
      await this.modalController.displayMessage(this.translate.instant('NOTICE'), this.translate.instant('GUARD.LEGACY.MSG2'));
    } catch (err) {
      this.modalController.displaySpinner(false);
      this.errorSvc.process(err);
    } finally {
      this.modalController.displaySpinner(false);
    }
  }

  async delete() {
    if (!this.legacyAccess) {
      return;
    }
    try {

      const answer = await this.modalController.displayQuestion(this.translate.instant('GUARD.LEGACY.DELETE.TITLE'), this.translate.instant('GUARD.LEGACY.DELETE.MSG'), this.translate.instant('CANCEL'), null, this.translate.instant('YES'));
      if (answer != 'TWO') {
        return
      }
      this.modalController.displaySpinner(true);
      await this.apiSvc.deleteAccess(this.legacyAccess.accessID);
      this.legacyAccess = undefined;
      this.guardAccess.legacyId = '';
      await this.apiSvc.updateAccess(this.guardAccess);
    } catch (err) {
      this.modalController.displaySpinner(false);
      this.errorSvc.process(err);
    } finally {
      this.modalController.displaySpinner(false);
    }
  }

  onChanged() {
    this.onChange.emit(this.legacyAccess);
  }
}
