
import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgIf } from '@angular/common';
import { ModalComponent } from '../../modal/modal.component';
import { Console } from 'src/app/lib/console';
import { ApiService } from 'src/app/services/api.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthzService } from 'src/app/services/authz.service';

@Component({
  selector: 'app-affiliate',
  standalone: true,
  templateUrl: './affiliate.component.html',
  styleUrl: './affiliate.component.scss',
  imports: [NgIf, ModalComponent, TranslateModule]
})
export class AffiliateComponent implements AfterViewInit {

  @Output() done = new EventEmitter<boolean>();
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;
  affiliateLink: string | null = null;
  suffix: string | null = null;
  affiliate_data: { affiliate: any; url: string | undefined; onboarding: boolean | undefined; } | null = null;
  isAffiliate = false;
  baseUrl = '';

  constructor(private apiSvc: ApiService, private authzSvc: AuthzService, public translate: TranslateService) {
    this.baseUrl = ` ${window.location.origin}/`;
    this.isAffiliate = this.authzSvc.appConfig.isAff;
  }

  ngAfterViewInit(): void {
    this.start();
  }

  private async start() {
    if (this.isAffiliate) {
      this.affiliate_data = await this.apiSvc.getAffiliate();
      if (!this.affiliate_data) {
        this.configureAffiliate();
      }
      if (!this.affiliate_data) {
        Console.log('Affiliate errors');
        this.done.emit(false);
        return;
      }

      if (this.affiliate_data?.affiliate.links.length == 0 && !this.affiliate_data?.onboarding) {
        let retry = false;
        do {
          try {
            this.suffix = await this.getSuffix();
            if (!this.suffix) {
              this.done.emit(false);
              return;
            }
            retry = false;
          } catch (e) {
            Console.error('Error getting suffix:', e);
            retry = true;
          }
        } while (retry);
      }

      if (this.affiliate_data?.affiliate.links.length > 0) {
        this.suffix = this.affiliate_data?.affiliate.links[0];
      }
    } else {
      //New affiliate
      let msg = this.translate.instant('APP_CONFIG.AFFILIATE.NEW.MSG');
      let response = await this.modalController.displayQuestion(this.translate.instant('APP_CONFIG.AFFILIATE.NEW.TITLE'), msg, this.translate.instant('CANCEL'), null, this.translate.instant('APP_CONFIG.AFFILIATE.NEW.BTN'));
      if (response == 'TWO') {
        Console.log('Affiliate accepted');
      } else {
        this.done.emit(false);
        return;
      }
      msg = this.translate.instant('APP_CONFIG.AFFILIATE.NEW.MSG2');
      response = await this.modalController.displayQuestion(this.translate.instant('APP_CONFIG.AFFILIATE.NEW.TITLE2'), msg, this.translate.instant('CANCEL'), null, this.translate.instant('YES'));
      if (response == 'TWO') {
        Console.log('Affiliate terms accepted');
      } else {
        this.done.emit(false);
        return;
      }
      await this.configureAffiliate();
    }
    if (this.affiliate_data?.onboarding) {
      await this.modalController.displayMessage(this.translate.instant('APP_CONFIG.AFFILIATE.ONBOARDING.TITLE'), this.translate.instant('APP_CONFIG.AFFILIATE.ONBOARDING.MSG'));
      this.goToDashboard();
    }
  }

  private async configureAffiliate() {
    this.modalController.displaySpinner(true);
    try {
      Console.log('Creating affiliate'); { }
      this.affiliate_data = await this.apiSvc.createAffiliate();
      Console.log('Affiliate created:', this.affiliate_data);
      this.authzSvc.appConfig.isAff = true;
      this.isAffiliate = true;
    } finally {
      this.modalController.displaySpinner(false);
    }
  }

  private async getSuffix(): Promise<string | null> {
    Console.log('Getting affiliate suffix');
    const suffix = await this.modalController.displayAffiliateLink();
    Console.log('Affiliate suffix:', suffix);
    if (suffix) {
      const response = await this.apiSvc.createAffiliateLink(suffix);
      if (response) {
        Console.log('Affiliate link created');
        this.affiliate_data?.affiliate.links.push(suffix);
        return suffix;
      }
    }
    return null;
  }

  copyAffiliateLink() {
    if (this.suffix) {
      Console.log('Copied to clipboard');
      navigator.clipboard.writeText(this.baseUrl + this.suffix);
    }
  }

  goToDashboard() {
    //open url in tab
    if (this.affiliate_data && this.affiliate_data.url)
      window.open(this.affiliate_data.url, '_blank');
  }

  cancel() {
    this.done.emit(false);
  }
}


